// Styles
import { useEffect, useState } from "react";
import "./character-card.styles.scss";

// Bootstrap Components
import { Button, Card, Col, Form, ProgressBar, Row } from "react-bootstrap";

// Libraries
import { Link } from "react-router-dom";

// Utils
import { getFirebaseImage, updateDocument } from "../../../utils/firebase/firebase.utils";


const CharacterCard = ({characterItem}) => {
    // Create the edit state
    const [characterArt, setCharacterArt] = useState(undefined);

    // Create the healthPercent state
    const [healthPercent, setHealthPercent] = useState(100);

    // Selected skill modifier
    const [skills, setSkills] = useState([{name: "Resist", "modifier": 2}, {name: "Force", "modifier": 1}, {name: "Scramble", "modifier": -1}]);

    // Selected skill modifier
    const [skillModifier, setSelectedSkillModifier] = useState(0);

    // Create a character state for editing the curernt health, stress, and stamina
    const [character, setCharacter ] = useState();
    
    useEffect(() => {
        if(characterItem) {
            // Set the base values for the health, stress, and stamina
            setCharacter(characterItem);

            // Load the character image from the backend store
            const getCharacterImage = async () => {
                // Create a reference from a Google Cloud Storage URI
                await getFirebaseImage(characterItem.imagesrc)
                .then((artUrl) => {setCharacterArt(artUrl)});
            }
        
            // Call for the character image
            getCharacterImage();

            // Set the health percent
            setHealthPercent(((characterItem.health.current*100)/characterItem.health.max));

            // Set the skills list
            const startingSkillList = [{name: "Resist", "modifier": characterItem.matter.resist}, {name: "Force", "modifier": characterItem.matter.force}, {name: "Scramble", "modifier": characterItem.matter.scramble}, {name: "Control", "modifier": characterItem.mechanics.control}, {name: "Create", "modifier": characterItem.mechanics.create}, {name: "Repair", "modifier": characterItem.mechanics.repair}, {name: "Discuss", "modifier": characterItem.mind.discuss}, {name: "Know", "modifier": characterItem.mind.know}, {name: "Peer", "modifier": characterItem.mind.peer}];
            const skillList = startingSkillList.concat(characterItem.skills);
            setSkills(skillList);

        } else {
            console.log("Character item not found");
        }
    }, [characterItem]);


    // Handle increment/decrement
    const onPlusMinusClick = (val, currentValue, name, maxValue) => {
        let value = currentValue + val;        

        // Ensure stress can't go below 0 or above max
        if (value < 0 ) {
           value = 0;
        } else if (value > maxValue) {
            value = maxValue;
        }

        setCharacter(prevState => {
            let newObj = {[name] : {
                    "current": value,
                    "max": maxValue
                }
            };

            // Update backend  
            updateDocument("characters", characterItem.uuid, newObj);

            return newObj;
        });
    }

    return (<Col className="mb-2" xl={3} lg={6} md={6} xs={12} >
    {characterItem && character &&
        <Card data-bs-theme="light" className="shadow-ml character-browse-card">
            <Link to={characterItem.link}>
                <Card.Img variant="top" className="img-card" src={characterArt} />
            </Link>
            <Card.Body className="mt-0 pt-0">
                <Link className="text-decoration-none" to={characterItem.link}>
                    <Row className="text-uppercase text-center text-black pt-2">
                        <Col xs={12}>
                            <p className="mb-0"><strong>{characterItem.playername}</strong></p>
                        </Col>
                    </Row>
                    {characterItem.nickname.length>0?<Card.Title><strong>{characterItem.name} ({characterItem.nickname})</strong></Card.Title>:<Card.Title><strong>{characterItem.name}</strong></Card.Title>}
                </Link>  
                <Row>
                    {healthPercent > 50? <ProgressBar variant="success" now={healthPercent} label={`${healthPercent}%`} visuallyHidden />: <ProgressBar variant="danger" now={healthPercent} label={`${healthPercent}%`} visuallyHidden />}
                </Row>
                <Row className="p-2">
                    <Col className="mx-auto mb-2" xl={3} lg={4} xs={6}>
                        <h3 className="primary-color"><i className="mt-3 mb-0 fa-solid fa-heart-crack secondary-color"></i></h3>
                        <p className="text-black">{character.health? character.health.current: characterItem.health.current} of {characterItem.health.max}</p>  
                        {character.health && <p><i as={Button} onClick={()=> onPlusMinusClick(-1, character.health.current, "health" , characterItem.health.max)} className="text-black me-4 fa-xl fa-solid fa-minus attribute-icon"></i><i as={Button} value={1} onClick={()=> onPlusMinusClick(1, character.health.current, "health" , characterItem.health.max)} className="secondary-color fa-solid fa-xl fa-plus attribute-icon"></i></p>}
                    </Col>
                    <Col className="mx-auto mb-2" xl={3} lg={4} xs={6}>
                        <h3 className="primary-color"><i className="mt-3 mb-0 fa-solid fa-bolt-lightning secondary-color"></i></h3>
                        <p className="text-black">{character.stress? character.stress.current: characterItem.stress.current} of {characterItem.stress.max}</p>
                        {character.stress && <p><i as={Button} onClick={()=> onPlusMinusClick(-1, character.stress.current, "stress" , characterItem.stress.max)} className="text-black me-4 fa-xl fa-solid fa-minus attribute-icon"></i><i as={Button} value={1} onClick={()=> onPlusMinusClick(1, character.stress.current, "stress" , characterItem.stress.max)} className="secondary-color fa-solid fa-xl fa-plus attribute-icon"></i></p>}
                    </Col>
                    <Col className="mx-auto mb-2" xl={3} lg={4} xs={6}>
                        <h3 className="primary-color"><i className="mt-3 mb-0 fa-solid fa-person-running secondary-color"></i></h3>
                        <p className="text-black">{character.stamina? character.stamina.current: characterItem.stamina.current} of {characterItem.stamina.max}</p>
                        {character.stamina && <p><i as={Button} onClick={()=> onPlusMinusClick(-1, character.stamina.current, "stamina" , characterItem.stamina.max)} className="text-black me-4 fa-xl fa-solid fa-minus attribute-icon"></i><i as={Button} value={1} onClick={()=> onPlusMinusClick(1, character.stamina.current, "stamina" , characterItem.stamina.max)} className="secondary-color fa-solid fa-xl fa-plus attribute-icon"></i></p>}
                    </Col>
                    <Col className="mx-auto mb-2" xl={3} lg={4} xs={6}>
                        <h3 className="primary-color"><i className="mt-3 mb-0 fa-solid fa-coins secondary-color"></i></h3>
                        <p className="text-black">{characterItem.money} {parseFloat(characterItem.money) === 1? "span" : "spans"}</p>
                    </Col>
                </Row> 
                {characterItem.skills && skills && <Row>
                <Col lg={8} xs={6}>
                    <p className="secondary-color"><strong>Skill</strong></p>
                    <Form.Select id="select-skill" aria-label="Select skill" onChange={(e)=>{setSelectedSkillModifier(e.target.value)}}>
                        <option name="default" value={0}>Select a skill</option>
                        {skills.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((item, index) => {return <option  key={index} value={item.modifier}>{item.name}</option>})}
                    </Form.Select>
                </Col>
                <Col lg={4} xs={6}>
                    <p className="secondary-color"><strong>Modifier</strong></p>
                    <p className="text-black">{skillModifier>=0? "+":""} {skillModifier}</p>
                </Col>
                </Row>}
            </Card.Body>
        </Card>
    }
    </Col>
    );
};

export default CharacterCard;