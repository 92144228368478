// Components
import { Button, Col, Container, Row, Spinner} from "react-bootstrap";
import GameCard from "../../../components/game/browse/game-card.component";

// Reducer
import { Link } from "react-router-dom";
import { addNewGame } from "../../../utils/firebase/firebase.utils";
import { useFirestore, useFirestoreCollectionData, useUser } from "reactfire";
import { collection, orderBy, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";


const AuthGameBrowse = () => {
    // Get the current user
    const { data: currentUser } = useUser();

    // Setup firestore ref
    const firestore = useFirestore();

    // set up query
    const gamesCollection = collection(firestore, 'games');
    const [gamesQuery, setQuery] = useState(query(gamesCollection, where('gm', '==', "000"), orderBy('name', 'asc')));

    // After retrieving the game, set the known corresponding states
    useEffect(() => {
        if (currentUser) {
            setQuery(query(gamesCollection, where('gm', '==', currentUser.uid), orderBy('name', 'asc')));
        }
    }, [currentUser, firestore, gamesCollection]);

    // ReactFire!
    const { status, data: games } = useFirestoreCollectionData(gamesQuery);

    // Create the start blank game
    let blankGame = {name: "New game", description:"", gm:"", map:"gs://trials-of-the-watcher.appspot.com/characters/placeholder.jpeg", guide: [], locations: [], 
    sessionnotes:[], shortname:"", system:"Thrice Dice", uid:""};   

    const onClickNewGame = () => {
        if (currentUser) {
              // Create the start blank game
              blankGame.gm = currentUser.uid;
            
              // Add the game in the backend, then nav there with a site reload
              addNewGame(blankGame).then((link)=>{window.location.href = link;});
        }
    }

   return (
   <Container>
        {status==="success" && currentUser?
            <Row>
                <Col className="d-flex">
                    <Button as={Link} onClick={onClickNewGame} className="me-auto" variant="primary"><i className="fa-solid fa-plus"></i>  Create new game</Button>
                </Col>
            </Row>
        :  <Container fluid>{status!== "success"? <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
            </Spinner>:<Container fluid>
                <h2>No games found</h2>
                <p className="lead">Once logged in, select the "Create new game" button to create a game.</p>
            </Container>}
            </Container>
        }
        <Row className="my-4">
            {currentUser && games && games.filter((filterItem)=>(filterItem.gm===currentUser.uid)).map((filterItem) => (<GameCard key={filterItem.uid} gameItem={filterItem} />))}
        </Row>
    </Container>  );
}

export default AuthGameBrowse;