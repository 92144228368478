// Bootstrap Component
import { Accordion, Button, Col, Container, Image, ListGroup, Row, Spinner, Tab, Tabs} from "react-bootstrap";

// Libraries
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import 'react-quill/dist/quill.snow.css';
import parse from 'html-react-parser';

// Styles
import "./game.styles.scss";

// Reducer
import { getFirebaseImage } from "../../utils/firebase/firebase.utils";
import CharacterCard from "../../components/characters/browse/character-card.component";
import OtherPlayerCard from "../../components/game/other-player/other-player-card.component";
import { useFirestore, useFirestoreCollectionData, useFirestoreDocData, useUser } from "reactfire";
import { collection, doc, query, where, orderBy } from "firebase/firestore";
import { Link } from "react-router-dom";
import GameLocation from "../../components/game/locations/game-location.component";
import NPC from "../../components/game/npcs/npc.component";
import Guide from "../../components/game/guide/guide.component";

const Game = () => {
    // Find corresponding game
    let {gameUrl} = useParams();

    // Get the current user
    const { status, data: currentUser } = useUser();

    // Setup firestore ref
    const firestore = useFirestore();

    // Set up game item
    const ref = doc(firestore, 'games', gameUrl);
    const { data: gameItem } = useFirestoreDocData(ref);

    // Set up characters query
    const charactersCollection = collection(firestore, 'characters');
    const [charactersQuery, setQuery] = useState(query(charactersCollection, where('users', 'array-contains-any', ["0000"])));

    // Set up characters query
    const [otherCharactersQuery, setOtherCharQuery] = useState(query(charactersCollection, where('users', 'array-contains-any', ["0000"])));

    // Determine if the current user has permissions to edit
    const [editPermission, setEditPermission] = useState(false);

    // Maintain the categories of NPCs
    const [npcCategories, setNpcCategories] = useState(new Set([]));

    // Maintain the categories of the field guide
    const [guideCategories, setGuideCategories] = useState(new Set([]));
    
    // Create navigation reference
    const navigate = useNavigate();

    // After retrieving the game, set the known corresponding states
    useEffect(() => {
        if (currentUser && gameItem) {
            setQuery(query(charactersCollection, where('game','==', gameItem.uid), where('users', 'array-contains-any', [currentUser.uid]), orderBy('name', 'asc')));
            setOtherCharQuery(query(charactersCollection, where('game','==', gameItem.uid), orderBy('name', 'asc')));

            // Set if the current user has permission to edit
            if(currentUser) {
                if (gameItem.gm === currentUser.uid) {
                    setEditPermission(true);
                }
                else {
                    setEditPermission(false);
                }
            }
        } else if (status==="success" && !currentUser) {
             // Redirect to login if not logged in
            navigate("/login");
        }
    }, [currentUser, charactersCollection, gameItem, navigate, status]);


    // ReactFire!
    const { data: characters } = useFirestoreCollectionData(charactersQuery);
    const { data: otherCharacters } = useFirestoreCollectionData(otherCharactersQuery);

    // Create game state item
    const [game, setGame] = useState({});
    // Create game state item
    const [mapArt, setMapArt] = useState({});

    useEffect(() => {

        if (gameItem) {
            // Set the game
            setGame(gameItem);

            if (gameItem.map) {
                // Load the map image from the backend store
                const getMapArt = async () => {
                    // Create a reference from a Google Cloud Storage URI
                    await getFirebaseImage(gameItem.map)
                    .then((artUrl) => {setMapArt(artUrl)});
                }
        
                // Call for the map image
                getMapArt();
            }

            // Set the NPC categories
            setNpcCategories([...new Set(gameItem.npcs.map(npc => npc.category))].sort((a, b) => a.localeCompare(b)));

            // Set the guideC categories
            setGuideCategories([...new Set(gameItem.guide.map(guide => guide.category))].sort((a, b) => a.localeCompare(b)));
        }

    }, [gameItem]);

    const viewButtons = () => {
        return (
            <Row>
                <Col className="mb-2 ms-auto character-profile-main" lg={1} md={4} xs={6}>
                    <Button className="link-button bg-transparent" onClick={()=>{navigate("/games/"+gameUrl+"/edit")}} title="Edit"><span className="fa-solid fa-pen-to-square fa-xl secondary-color"></span></Button>
                </Col>
            </Row>
        )
    }


    return(
   <Container fluid>
        {(status==="success" && currentUser) && <Container fluid>
        {(!gameItem || !game.name) && <Container><Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
            </Spinner></Container>
        }
        {gameItem && game.name && <Container fluid>
            {editPermission && viewButtons()}
            {game.shortname.length>0?<h1>{game.name} ({game.shortname})</h1>:<h1>{game.name}</h1>}
            <Row className="d-flex justify-content-center">
                {characters && characters.filter((filterItem)=>(filterItem.users.includes(currentUser.uid))).map((filterItem, index) => {return <CharacterCard key={index} characterItem={filterItem} />})}
                <Col><Row className="mx-0 px-0 d-flex justify-content-center">{otherCharacters && otherCharacters.filter((filterItem)=>(!filterItem.users.includes(currentUser.uid))).map((filterItem, index) => {return <OtherPlayerCard key={index} characterItem={filterItem} />})}</Row></Col>
            </Row>            
            <Row className="character-profile-main m-2 mb-5">
                    <Tabs defaultActiveKey="setting" id="setting" className="pt-0 mb-3" data-bs-theme="dark" fill justify>
                        <Tab eventKey="setting" title={<span><i className="fa-solid fa-mountain" />  Setting</span>}>
                            <Container fluid className="text-md-start py-3">
                                <Row className="d-flex align-items-center ">
                                    <Col xs={12} md={8}  className="mb-5 align-self-center mx-auto">
                                        <Image className="img-fluid" loading="lazy" alt={game.name} src={mapArt} />
                                    </Col>
                                </Row>
                                {game.locations.length>0 && 
                                <Row data-bs-theme="dark" className="mt-2">
                                    <h3>Locations</h3>
                                    <Accordion alwaysOpen className="my-4">
                                        {game.locations.filter(location => location.status !== "hidden").sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((filterItem, index) => {
                                            return <GameLocation key={index} index={index} filterItem={filterItem} />
                                        })}
                                    </Accordion>
                                </Row>}
                                <Row>
                                    <h3>{game.name}</h3>
                                    {parse(game.description)}
                                </Row>
                            </Container>
                        </Tab>

                        <Tab eventKey="NPCs" id="npcs" title={<span><i className="fa-solid fa-person" />  NPCs</span>}>
                            <Container data-bs-theme="dark" fluid className="text-md-start py-3">
                                <h3>Story characters</h3>
                                <Accordion alwaysOpen className="mx-0 my-4">
                                {npcCategories.map((category, index) => { return <Accordion.Item eventKey={String(index)} key={index}>
                                    <Accordion.Header><h4>{category}</h4></Accordion.Header> 
                                        <Accordion.Body>
                                            <ListGroup className="border-0" variant="flush">
                                                {game.npcs.filter(npc => (npc.status!=="hidden" && npc.category === category)).sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((filterItem, index) => {
                                                    return (<NPC key={index} filterItem={filterItem} />);
                                                    }
                                                )}
                                            </ListGroup>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                })}
                                </Accordion>
                            </Container>
                        </Tab>
                        {game.guide.length > 0 && <Tab eventKey="Field guide" id="guide" title={<span><i className="fa-solid fa-binoculars" />  Field Guide</span>}>
                            <Container data-bs-theme="dark"  fluid className="text-md-start py-3">
                                <Accordion alwaysOpen className="mx-0 my-4">
                                        {guideCategories.map((category, index) => { return <Accordion.Item eventKey={String(index)} key={index}>
                                            <Accordion.Header><h4>{category}</h4></Accordion.Header> 
                                            <Accordion.Body>
                                                <ListGroup className="border-0" variant="flush">
                                                    {game.guide.filter(guide=> (guide.status!=="hidden" && guide.category === category)).sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((filterItem, index) => {
                                                        return (<Guide key={index} filterItem={filterItem} />);
                                                        }
                                                    )}
                                                </ListGroup>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        })}
                                </Accordion>
                            </Container>
                        </Tab>}
                        <Tab eventKey="notes" id="notes" title={<span><i className="fa-solid fa-book" />  Story So Far</span>}>
                            <Container fluid data-bs-theme="dark" className="text-md-start py-3">
                                <h3>Session notes</h3>
                                <Button className="ms-auto" as={Link} to={`/games/${gameUrl}/notes/edit`}><i className="fa-solid fa-pen-nib" />  New Session Notes</Button>
                                <Accordion className="mt-4">
                                    {game.sessionnotes.sort((a, b) => a.sessionnumber - b.sessionnumber).map((filterItem, index) => {
                                        return (<Accordion.Item key={index} eventKey={index}>
                                            <Accordion.Header><h4>Session {filterItem.sessionnumber}</h4></Accordion.Header>
                                            <Accordion.Body>{parse(filterItem.notes)}</Accordion.Body>
                                        </Accordion.Item>);
                                        }
                                    )}
                                </Accordion>
                            </Container>
                        </Tab>
                    </Tabs>
                </Row>  
            </Container>}
        </Container>}
    </Container>
    )
}

export default Game;