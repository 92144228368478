// Libraries
import { Link } from "react-router-dom";
import { useSigninCheck} from "reactfire";

// Bootstrap Components
import { Container, Dropdown, Image, Nav, Navbar } from 'react-bootstrap'

// Styles
import './header.styles.scss';

// Utils
import { signOutFromAuth } from "../../../utils/firebase/firebase.utils";


const signOutHandler = async () => await signOutFromAuth();

const Header = () => {
  // Get the current user
  const {status, data: signInCheckResult } = useSigninCheck();

  const userSection = () => {
    if (status==="success" && signInCheckResult.signedIn) {
      return (<Dropdown className="d-inline">
          <Dropdown.Toggle style={{backgroundColor: "transparent"}} id="dropdown-user" className="link-button">
            <span className="fa-solid fa-circle-user secondary-color" alt="user" />
          </Dropdown.Toggle>
          <Dropdown.Menu align="end" title="Dropdown end" id="dropdown-menu-align-end">
            {false && <Dropdown.Item as={Link} to="/profile/settings" className="navbar-link" eventKey="1">Settings</Dropdown.Item> && <Dropdown.Divider />}
            <Dropdown.Item className="navbar-link" eventKey="3" onClick={signOutHandler}>Sign out</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>);
    } else {
      return (<Container>
        <Nav.Link className="link-button mx-2" as={Link} to="/register">register</Nav.Link>
        <Nav.Link className="link-button mx-2" as={Link} to="/login">login</Nav.Link>
      </Container>);
    }
  }

  return (
      <header>
      <Navbar variant="secondary" expand="lg">
      <Container fluid className="mx-5 mb-2">
        <Navbar.Brand as={Link} to="/">
          <Image className="logo" src="/media/main-pages/tow-logo.png" alt="Trials of the Watcher" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav" variant="secondary"  className="align-items-center justify-content-center">
        {status==="success" && signInCheckResult.signedIn &&
          <Nav className="me-auto">
            <Nav.Link as={Link} className="link-button" to="/games/aKdYDydNAj1u80Ecao8q">game</Nav.Link>
            
            <Nav.Link as={Link} className="link-button" to="/characters">characters</Nav.Link>
            
          </Nav>
        }
          <Nav className="ms-auto">
            { userSection() }            
          </Nav>

        </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
