// Styles
import { useEffect, useState } from "react";
import "./other-player-card.styles.scss";

// Bootstrap Components
import { Card, Col, ProgressBar, Row } from "react-bootstrap";

// Libraries
import { Link } from "react-router-dom";

// Utils
import { getFirebaseImage } from "../../../utils/firebase/firebase.utils";


const OtherPlayerCard = ({characterItem}) => {
    // Create the edit state
    const [characterArt, setCharacterArt] = useState(undefined);

    // Create the healthPercent state
    const [healthPercent, setHealthPercent] = useState(100);

    // Create the link
    const [link, setLink] = useState("");
    
    useEffect(() => {
        if(characterItem) {
            // Load the character image from the backend store
            const getCharacterImage = async () => {
                // Create a reference from a Google Cloud Storage URI
                await getFirebaseImage(characterItem.imagesrc)
                .then((artUrl) => {setCharacterArt(artUrl)});
            }
        
            // Call for the character image
            getCharacterImage();

            // Set the health percent
            setHealthPercent(((characterItem.health.current*100)/characterItem.health.max));

            // Set the link
            setLink("/characters/" + characterItem.uuid);
        } else {
            console.log("Character item not found");
        }
    }, [characterItem]);


    return (<Col className="mb-2" xl={4} lg={6} md={6} xs={12} >
    {characterItem &&
        <Card className="shadow-ml player-browse-card">
            <Link to={link}>
                <Card.Img variant="top" className="img-card" src={characterArt} />
            </Link>
            <Card.Body className="mt-0 pt-0">
                <Link className="text-decoration-none stretched-link" to={link}>
                    <Row className="text-uppercase text-center text-white pt-2">
                        <Col xs={12}>
                            <p className="mb-0"><strong>{characterItem.playername}</strong></p>
                        </Col>
                    </Row>
                    {characterItem.nickname.length>0?<Card.Title><strong>{characterItem.name}</strong><p className="lead d-lg-block d-none align-content-center">({characterItem.nickname})</p></Card.Title>:<Card.Title><strong>{characterItem.name}</strong><p><br className="d-lg-flex d-none"></br></p></Card.Title>}
                </Link>  
                <Row>
                    {healthPercent > 50? <ProgressBar data-bs-theme="dark"  variant="success" now={healthPercent} label={`${healthPercent}%`} visuallyHidden />: <ProgressBar data-bs-theme="dark"  variant="danger" now={healthPercent} label={`${healthPercent}%`} visuallyHidden />}
                </Row>
                <Row className="p-1 d-lg-flex align-items-center d-none">
                    <Col className="mx-auto mb-2" lg={4} xs={6}>
                        <h3 className="primary-color"><i className="mt-3 mb-0 fa-solid fa-heart-crack secondary-color"></i></h3>
                        <p className="text-white">{characterItem.health.current} of {characterItem.health.max}</p>  
                    </Col>
                    <Col className="mx-auto mb-2" lg={4} xs={6}>
                        <h3 className="primary-color"><i className="mt-3 mb-0 fa-solid fa-bolt-lightning secondary-color"></i></h3>
                        <p className="text-white">{characterItem.stress.current} of {characterItem.stress.max}</p>
                    </Col>
                    <Col className="mx-auto mb-2" lg={4} xs={6}>
                        <h3 className="primary-color"><i className="mt-3 mb-0 fa-solid fa-person-running secondary-color"></i></h3>
                        <p className="text-white">{characterItem.stamina.current} of {characterItem.stamina.max}</p>
                    </Col>
                </Row> 
            </Card.Body>
        </Card>
    }
    </Col>
    );
};

export default OtherPlayerCard;