// Bootstrap Component
import { Button, Col, Container, Form, Image, ListGroup, ProgressBar, Row, Spinner, Tab, Tabs} from "react-bootstrap";

// Libraries
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Styles
import "./game-edit.styles.scss";

// Reducer
import { getFirebaseImage, updateDocument, uploadImageToStorage} from "../../utils/firebase/firebase.utils";
import { useFirestore, useFirestoreDocData, useUser } from "reactfire";
import { doc} from "firebase/firestore";
import EditGameLocation from "../../components/game/locations/game-location-edit.component";
import EditNPC from "../../components/game/npcs/npc-edit.component";
import EditGuide from "../../components/game/guide/guide-edit.component";

const EditGame = () => {
    // Find corresponding game
    let {gameUrl} = useParams();

    // Get the current user
    const { status, data: currentUser } = useUser();

    // Setup firestore ref
    const firestore = useFirestore();

    // Set up game item
    const ref = doc(firestore, 'games', gameUrl);
    const { data: gameItem } = useFirestoreDocData(ref);

    // Determine if the current user has permissions to edit
    const [editPermission, setEditPermission] = useState(false);

    // Create the upload state
    const [uploadState, setUploadState] = useState(-1);

    // Create the start blank game
    let blankGame = {name: "New game", description:"", gm:"", guide: [], locations: [], map:"gs://trials-of-the-watcher.appspot.com/characters/placeholder.jpeg", 
    media:[], npcs: [], sessionnotes:[],shortname:"", system:"Thrice Dice", uid:""};   


    // Create the character state for editing
    const [game, setGame] = useState(blankGame);

    // Create the last known state for discard reverts
    const [lastKnown, setLastKnown] = useState(blankGame);
    
    // Create navigation reference
    const navigate = useNavigate();

    // After retrieving the game, set the known corresponding states
    useEffect(() => {
        if (currentUser && gameItem) {
            setGame(gameItem);

            // Set if the current user has permission to edit
            if(currentUser) {
                if (gameItem.gm === currentUser.uid) {
                    setEditPermission(true);
                }
                else {
                    setEditPermission(false);
                }
            }

        } else if (status==="success" && !currentUser) {
             // Redirect to login if not logged in
            navigate("/login");
        }
    }, [currentUser, gameItem, navigate, status]);


    // Create game state item
    const [mapArt, setMapArt] = useState({});
    
    
    useEffect(() => {
        if (game.map) {
            // Load the map image from the backend store
            const getMapArt = async () => {
                // Create a reference from a Google Cloud Storage URI
                await getFirebaseImage(game.map)
                .then((artUrl) => {setMapArt(artUrl)});
            }
    
            // Call for the map image
            getMapArt();
        }
    }, [game.map]);

    
    // Handle form changes
    const handleFormChange = e => {
        if (e.target) {
            const { name, value } = e.target;

            let parsedVal = value;
            // Check if the value is a number, and if so, convert the type
            if (value && value.length > 0 && !isNaN(value)) {
                parsedVal = parseFloat(value);
            }

            setGame(prevState => ({
                ...prevState,
                [name]: parsedVal
            }));
            console.log({ [name]: parsedVal});
        }
    };

    /*
    // Handle sub-attribute changes
    const handleSubAttributeChange = (parentName, parent, name, value) => {
        let parsedVal = value;
        // Check if the value is a number, and if so, convert the type
        if (value && !isNaN(value)) {
            parsedVal = parseInt(value);
        }

        setGame(prevState => ({
            ...prevState, [parentName] : { 
                ...parent, 
                [name]: parsedVal
            }
        }));
    };
    */

    // Save form
    const saveForm = () => {

        // Update the last known state (in case of multiple edits/discards while on this page)
        setLastKnown(game);

        // Update the game in the backend
        updateDocument("games", gameItem.uid, game).then(navigate("/games/"+gameUrl));

    }


    const addItem = (name, currentList, newItem) => {
        // Push the new item to the list
        const newList = currentList;
        newList.push(newItem);

        const eObj = {
            "target": {
                "name" : name,
                "value": newList
            }
        };

        // Pass the form change
        handleFormChange(eObj);
    }

    const removeItem = (name, currentList, index) => {
        // Verify that it's a valid, spliceable index
        if (index > -1 && index < currentList.length) {
            let newList = currentList;

            newList.splice(index, 1)
       

            const eObj = {
                "target": {
                    "name" : name,
                    "value": newList
                }
            };

            // Pass the form change
            handleFormChange(eObj);
        }
    }
    

    const handleListChange = (event, index, list, parentName) => {
        const {name, value} = event.target;

        let parsedVal = value;
        // Check if the value is a number, and if so, convert the type
        if (value && value.length>0 && !isNaN(value)) {
            parsedVal = parseFloat(value);
        }
    
        // Create a new lisy with the modified value
        let newList = [...list];
        newList[index] = {...list[index], [name]: parsedVal};
    
        // Update the form field state
        handleFormChange({target: {name: [parentName], value: newList}});
      };

    const handleUpload = e => {
        const file = e.target.files[0];
        const name = e.target.name;

        const filePath = "games/"+gameUrl;

        if (file) {
            // Set progress to 1
            setUploadState(1);

            // Upload to storage
            const getGameImageRef = async (fileRef) => {
                // Create a reference from a Google Cloud Storage URI
                const uploadTask = await uploadImageToStorage(fileRef, filePath);


                // Register three observers:
                // 1. 'state_changed' observer, called any time the state changes
                // 2. Error observer, called on failure
                // 3. Completion observer, called on successful completion
                uploadTask.task.on('state_changed', 
                (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                // Set upload progress and show progress bar
                setUploadState(progress);
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                    default:
                        console.log("Other upload state detected")
                }
                }, 
                (error) => {
                    // Handle unsuccessful uploads
                }, 
                (ref) => {
                    setUploadState(100);
                    const artUrl = 'gs://trials-of-the-watcher.appspot.com/' + filePath + '/' + file.name;

                    // Handle successful uploads on complete
                    const eObj = {
                        "target": {
                            "name" : [name],
                            "value": artUrl
                        }
                    };

                    console.log(eObj);

                    // Call for the game image from the backend store
                    handleFormChange(eObj);
                });
            }
            // Call the upload function
            getGameImageRef(file);
        }
    }

    // Create the buttons to use while in the editState
    const editButtons = () => {
        return (

            <Row>
                <Col className="mb-2 ms-auto game-profile-main" lg={3} md={4} xs={6}>
                    <Button className="link-button bg-transparent me-4" onClick={saveForm} title="Save"><span className="fa-solid fa-floppy-disk fa-xl secondary-color"></span></Button>
                    <Button className="link-button bg-transparent" onClick={()=>{setGame(lastKnown); navigate("/games/"+gameUrl)}} title="Discard"><span className="fa-solid fa-x fa-xl secondary-color"></span></Button>
                </Col>
            </Row>
        )
    }


    return(
   <Container fluid>
        {(status==="success" && currentUser) && <Container fluid>
        {(!gameItem || !game.name) && <Container><Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
            </Spinner></Container>
        }
        {gameItem && game.name && <Container data-bs-theme="dark" fluid>
            {editPermission && editButtons()}
            <Row>
                <Col xs={12} md={8}>
                    <Form.Group>
                        <Form.Label>Game name</Form.Label>
                        <h1><Form.Control placeholder="Name" name="name" onChange={handleFormChange} data-bs-theme="dark" size="lg" type="text" value={game.name} /></h1>        
                    </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                    <Form.Group>
                        <Form.Label>Short name</Form.Label>
                        <h1><Form.Control placeholder="Shortened name" name="shortname" onChange={handleFormChange} data-bs-theme="dark" size="lg" type="text" value={game.shortname} /></h1>        
                    </Form.Group>
                </Col>
            </Row>
            
            <Row className="game-profile-main m-2 pt-3 mb-5">
                    <Tabs defaultActiveKey="setting" id="setting" className="pt-0 mb-3" fill justify>
                        <Tab eventKey="setting" title={<span><i className="fa-solid fa-mountain" />  Setting</span>}>
                            <Container fluid className="text-md-start py-3">                               
                                <h3>Map</h3>
                                <Row>
                                    <Col lg={4} md={6} xs={12}>
                                        <div className="d-inline-flex align-middle">
                                            <Image className="img-fluid" loading="lazy" alt={game.name} src={mapArt} />
                                        </div>
                                    </Col>
                                    <Col lg={8} md={6} xs={12}>
                                        <Form.Group controlId="formFile" className="mb-3">
                                            <Form.Label>Upload map art</Form.Label>
                                            <Form.Control name="map" accept="image/*" onChange={handleUpload} type="file" />
                                            {uploadState > 0 && uploadState < 101 && <ProgressBar now={uploadState} label={`${uploadState}%`} visuallyHidden></ProgressBar>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="text-md-start my-4">
                                    <h3>Locations</h3>
                                    <Container fluid className="text-md-start py-3">
                                        <Button variant="secondary-outline" onClick={()=>{addItem("locations", game.locations, {name:"", description: "", status: "hidden", imagesrc: ""})}} className="primary-color mb-1 me-auto"><span className="fa-xl fa-solid fa-plus secondary-color me-1"></span> Add new</Button>
                                        <ListGroup data-bs-theme="dark" variant="flush">
                                            {game.locations && game.locations.map((filterItem, index) => {
                                                return (
                                                    <EditGameLocation key={index} game={game} gameUrl={gameUrl} index={index} filterItem={filterItem} removeItem={removeItem} handleListChange={handleListChange} />
                                                );
                                            })}
                                        </ListGroup>
                                    </Container>
                                </Row>
                                <Row>
                                    <Col className="mt-3 mb-4">
                                        <h3>Description</h3>
                                        <ReactQuill theme="snow" name="description" onChange={(text)=>{handleFormChange({"target": {"name": "description", "value": text}})}} value={game.description}></ReactQuill>
                                    </Col>
                                </Row>
                                    
                            </Container>
                        </Tab>

                        <Tab eventKey="NPCs" id="npcs" title={<span><i className="fa-solid fa-person" />  NPCs</span>}>
                            <Container fluid className="text-md-start py-3">
                                <h3>Story characters</h3>
                                <Button variant="secondary-outline" onClick={()=>{addItem("npcs", game.npcs, {name:"", description: "", status: "hidden", imagesrc: "", category: ""})}} className="primary-color mb-1"><span className="fa-xl fa-solid fa-plus secondary-color me-1"></span> Add new</Button>

                                <ListGroup data-bs-theme="dark" variant="flush">
                                    {game.npcs && game.npcs.map((filterItem, index) => {
                                        return (
                                            <EditNPC key={index} game={game} gameUrl={gameUrl} index={index} filterItem={filterItem} removeItem={removeItem} handleListChange={handleListChange} />
                                        );
                                    })}
                                </ListGroup>
                            </Container>
                        </Tab>
                        <Tab eventKey="Field guide" id="guide" title={<span><i className="fa-solid fa-binoculars" />  Field Guide</span>}>
                            <Container fluid className="text-md-start py-3">
                                <h3>Field guide</h3>

                                <Button variant="secondary-outline" onClick={()=>{addItem("guide", game.guide, {name:"", description: "", status: "hidden", imagesrc: "", category: "Artifacts"})}} className="primary-color mb-1"><span className="fa-xl fa-solid fa-plus secondary-color me-1"></span> Add new</Button>

                                <ListGroup data-bs-theme="dark" variant="flush">
                                    {game.guide && game.guide.map((filterItem, index) => {
                                        return (
                                            <EditGuide key={index} game={game} gameUrl={gameUrl} index={index} filterItem={filterItem} removeItem={removeItem} handleListChange={handleListChange} />
                                        );
                                    })}
                                </ListGroup>
                                
                            </Container>
                        </Tab>
                    </Tabs>
                </Row>  
            </Container>}
        </Container>}
    </Container>
    )
}

export default EditGame;