// Bootstrap Component
import { Button, Card, Col, Container, Form, Image, ListGroup, ProgressBar, Row, Spinner, Tab, Tabs} from "react-bootstrap";

// Libraries
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { doc } from "firebase/firestore";
import { useFirestore, useFirestoreDocData, useUser } from "reactfire";


// Styles
import "./character.styles.scss";

// Reducer
import { getFirebaseImage, updateDocument, uploadImageToStorage } from "../../utils/firebase/firebase.utils";


const Character = () => {

    // Create the edit state
    const [editState, setEditState] = useState(1);

    // Create the edit state
    const [characterArt, setCharacterArt] = useState();

    // Create the upload state
    const [uploadState, setUploadState] = useState(-1);

    // Create the start blank character
    const blankCharacter = {"armor": 0, "background": "", "carry": 3, "disadvantages": [], "equipment":[], "game": "", "skillpoints": 6,
    "health": {}, "imagesrc":"", "items":[], "link":"", "matter":{}, "mechanics":{}, "mind":{}, "money":0, "name":"", "nickname":"","notes":"",
    "player":"","playername":"","profession":"","publicnotes":"", "scars":0, "skal":"Dactyl", "stamina":{}, "stress":{}, "talentpoints": 0, "talents":[],
    "traits": [], "users":[], "uuid":""};

    // Create the character state for editing
    const [character, setCharacter] = useState(blankCharacter);

    // Create the last known state for discard reverts
    const [lastKnown, setLastKnown] = useState(blankCharacter);

    // Determine if the current user has permissions to edit
    const [editPermission, setEditPermission] = useState(false);

    // Find corresponding character
    let {characterUrl} = useParams();

    // Get the current user
    const { data: currentUser } = useUser();

    // Setup firestore ref
    const firestore = useFirestore();

    // Set up character item
    const ref = doc(firestore, 'characters', characterUrl);
    const { status, data: characterItem } = useFirestoreDocData(ref);
    
    // After retrieving the character, set the known corresponding states
    useEffect(() => {
        if (characterItem) {
            // Set the character to the entry from the store
            setCharacter(characterItem);
            setLastKnown(characterItem);

            // Set if the current user has permission to edit
            if(currentUser) {
                if (characterItem.users.includes(currentUser.uid)) {
                    setEditPermission(true);
                }
                else {
                    setEditPermission(false);
                }
            }
        }
    }, [characterItem, currentUser]);

    // Whenever the character art is updated, refresh the related state that gets it from the backend store
    useEffect(() => {
        if (character.imagesrc) {
            // Load the character image from the backend store
            const getCharacterImage = async () => {
                // Create a reference from a Google Cloud Storage URI
                await getFirebaseImage(character.imagesrc)
                .then((artUrl) => {setCharacterArt(artUrl); setUploadState(101);});
            }
    
            // Call for the character image
            getCharacterImage();
        }
    }, [character.imagesrc]);

    // Handle form changes
    const handleFormChange = e => {
        const { name, value } = e.target;

        let parsedVal = value;
        // Check if the value is a number, and if so, convert the type
        if (value && value.length > 0 && !isNaN(value)) {
            parsedVal = parseFloat(value);
        }

        setCharacter(prevState => ({
            ...prevState,
            [name]: parsedVal
        }));
    };

    // Handle sub-attribute changes
    const handleSubAttributeChange = (parentName, parent, name, value) => {
        let parsedVal = value;
        // Check if the value is a number, and if so, convert the type
        if (value && !isNaN(value)) {
            parsedVal = parseInt(value);
        }

        setCharacter(prevState => ({
            ...prevState, [parentName] : { 
                ...parent, 
                [name]: parsedVal
            }
        }));
    };

    // Save form
    const saveForm = () => {
        // Put the page back in view mode
        setEditState(3);

        // Update the last known state (in case of multiple edits/discards while on this page)
        setLastKnown(character);

        // Update the character in the backend
        updateDocument("characters", characterItem.uuid, character).then(setEditState(1));

    }
    

    // Handle increment/decrement
    const onPlusMinusClick = (val, currentValue, name, maxValue) => {
        let value = currentValue + val;        

        // Ensure stress can't go below 0 or above max
        if (value < 0 ) {
           value = 0;
        } else if (value > maxValue) {
            value = maxValue;
        }

        setCharacter(prevState => {
            let newObj = {...prevState, [name] : {
                "current": value,
                "max": maxValue
            }};

            // Update backend  
            updateDocument("characters", characterItem.uuid, newObj);

            return newObj;
        });

    }

    const addItem = (name, currentList, newItem) => {
        // Push the new item to the list
        const newList = currentList;
        newList.push(newItem);

        const eObj = {
            "target": {
                "name" : name,
                "value": newList
            }
        };

        // Pass the form change
        handleFormChange(eObj);
    }

    const removeItem = (name, currentList, index) => {
        // Verify that it's a valid, spliceable index
        if (index > -1 && index < currentList.length) {
            let newList = currentList;

            newList.splice(index, 1)
       

            const eObj = {
                "target": {
                    "name" : name,
                    "value": newList
                }
            };

            // Pass the form change
            handleFormChange(eObj);
        }
    }
    

    const handleListChange = (event, index, list, parentName) => {
        const {name, value} = event.target;

        let parsedVal = value;
        // Check if the value is a number, and if so, convert the type
        if (value && value.length>0 && !isNaN(value)) {
            parsedVal = parseFloat(value);
        }
    
        // Create a new lisy with the modified value
        let newList = [...list];
        newList[index] = {...list[index], [name]: parsedVal};
    
        // Update the form field state
        handleFormChange({target: {name: [parentName], value: newList}});
      };
    

    const handleUpload = e => {
        const file = e.target.files[0];
        if (file) {
            // Set progress to 1
            setUploadState(1);

            // Upload to storage
            const getCharacterImageRef = async (fileRef) => {
                // Create a reference from a Google Cloud Storage URI
                const uploadTask = await uploadImageToStorage(fileRef, 'characters');


                // Register three observers:
                // 1. 'state_changed' observer, called any time the state changes
                // 2. Error observer, called on failure
                // 3. Completion observer, called on successful completion
                uploadTask.task.on('state_changed', 
                (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                // Set upload progress and show progress bar
                setUploadState(progress);
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                    default:
                        console.log("Other upload state detected")
                }
                }, 
                (error) => {
                    // Handle unsuccessful uploads
                }, 
                () => {
                    setUploadState(100);
                    const artUrl = 'gs://trials-of-the-watcher.appspot.com/characters/' + file.name;

                    // Handle successful uploads on complete
                    const eObj = {
                        "target": {
                            "name" : "imagesrc",
                            "value": artUrl
                        }
                    };

                    // Call for the character image from the backend store
                    handleFormChange(eObj);
                });
            }
            // Call the upload function
            getCharacterImageRef(file);
        }
    }

    // Handle automatic updates
    const handleFieldAutoUpdate = (e) => {
        const { name, value } = e.target;

        let parsedVal = value;
        // Check if the value is a number, and if so, convert the type
        if (value && value.length > 0 && !isNaN(value)) {
            parsedVal = parseFloat(value);
        }

        setCharacter(prevState => {
            let newObj = {...prevState, [name] : [parsedVal]};

            // Update backend  
            updateDocument("characters", characterItem.uuid, newObj);

            return newObj;
        });

    }

    // Create the rows specific for armor
    const armorEquipment = (filterItem, index) => {
        if(editState === 1) {
            return (
                <Col>
                    <Row><p><strong>Armor</strong></p></Row>
                    <Row><p>+ {filterItem.armor} HP</p></Row>
                </Col>
            )
        } else {
            return (
                <Col>
                    <Row><p><strong>Armor</strong></p></Row>
                    <Row><Form.Control placeholder="Armor (HP)" name="armor" onChange={event => handleListChange(event, index, character.equipment, "equipment")} data-bs-theme="dark" type="number" value={filterItem.armor} /></Row>
                </Col>
            )
        }
        
    }

    // Create the rows specific for weapons
    const weaponEquipment = (filterItem, index) => {
        if(editState === 1) {
            return(
            <>
                <Col>
                    <Row className="m"><p><strong>Damage</strong></p></Row>
                    <Row><p>{filterItem.damage} damage</p></Row>
                </Col>
                <Col>
                    <Row><p><strong>Ranged</strong></p></Row>
                    <Row><p>{filterItem.ranged?"Ranged":"Melee"}</p></Row>
                </Col>
            </>)
        } else {
            return (<>
                <Col>
                    <Row><p><strong>Damage</strong></p></Row>
                    <Row><Form.Control placeholder="Damage" name="damage" onChange={event => handleListChange(event, index, character.equipment, "equipment")} data-bs-theme="dark" type="number" value={filterItem.damage} /></Row>
                </Col>
                <Col>
                    <Row><p><strong>Ranged</strong></p></Row>
                    <Row><Form><Form.Check name="ranged" onChange={event => handleListChange(event, index, character.equipment, "equipment")} data-bs-theme="dark" twpe="switch" checked={filterItem.ranged} value={filterItem.ranged} /></Form></Row>
                </Col>
            </>)
        }

        
    }

    // Create the buttons to use while not in the editState
    const viewButtons = () => {
        return (
            <Row>
                <Col className="mb-2 ms-auto character-profile-main" lg={1} md={4} xs={6}>
                    <Button className="link-button bg-transparent" onClick={()=>{setEditState(2)}} title="Edit"><span className="fa-solid fa-pen-to-square fa-xl secondary-color"></span></Button>
                </Col>
            </Row>
        )
    }

    // Create the buttons to use while in the editState
    const editButtons = () => {
        return (

            <Row>
                <Col className="mb-2 ms-auto character-profile-main" lg={3} md={4} xs={6}>
                    <Button className="link-button bg-transparent me-4" onClick={saveForm} title="Save"><span className="fa-solid fa-floppy-disk fa-xl secondary-color"></span></Button>
                    <Button className="link-button bg-transparent" onClick={()=>{setCharacter(lastKnown); setEditState(1); }} title="Discard"><span className="fa-solid fa-x fa-xl secondary-color"></span></Button>
                </Col>
            </Row>
        )
    }


    const viewModeContent = () => {
        return (<Container>
            <Container>
            <Row>
                <Container className="d-grid mb-5 gap-2">
                    {editPermission && viewButtons()}
                </Container>
            </Row>
            <Row>
                <Col md={6} xs={12}>
                    <div className="d-inline-flex align-middle character-avatar-container">
                        <Image data-bs-theme="dark" loading="lazy" alt={character.name} src={characterArt} />
                    </div>
                </Col>
                <Col md={6} xs={12}>
                    {characterItem.nickname.length>0?<h1>{characterItem.name} ({characterItem.nickname})</h1>:<h1>{characterItem.name}</h1>}
                    <p className="subtext mb-5">of skal {character.skal}</p>
                    <p className="lead">{character.publicnotes}</p>
                </Col>
            </Row>          
        </Container>
        
        <Row className="m-2">
            <Col>
                <Card className="character-profile-card my-5 py-2 shadow-sm">
                    <Card.Title><h2>Trials of the Watcher</h2><p className="text-uppercase lead">{character.playername}</p></Card.Title>
                    <Card.Body className="pb-2 text-center">
                        <Row>
                            <Col className="mx-auto mb-2" lg={3} md={4} xs={6}>
                                <h3 className="primary-color"><p><i className="mt-3 mb-0 fa-solid fa-shield-halved secondary-color"></i></p>Armor</h3>
                                <p className="lead card-subtext">{character.armor}</p>
                            </Col>
                            <Col className="mx-auto mb-2" lg={3} md={4} xs={6}>
                                <h3 className="primary-color"><p><i className="mt-3 mb-0 fa-solid fa-heart-crack secondary-color"></i></p>Health</h3>
                                <p className="lead card-subtext">{character.health.current} of {character.health.max}</p>
                                {
                                    editPermission && <p><i as={Button} onClick={()=> onPlusMinusClick(-1, character.health.current, "health" , character.health.max)} className="me-4 fa-xl fa-solid fa-minus secondary-color attribute-icon"></i><i as={Button} value={1} onClick={()=> onPlusMinusClick(1, character.health.current, "health" , character.health.max)} className="fa-solid fa-xl fa-plus attribute-icon"></i></p>
                                }
                            </Col>
                            <Col className="mx-auto mb-2" lg={3} md={4} xs={6}>
                                <h3 className="primary-color"><p><i className="mt-3 mb-0 fa-solid fa-bolt-lightning secondary-color"></i></p>Stress</h3>
                                <p className="lead card-subtext">{character.stress.current} of {character.stress.max}</p>
                                {
                                    editPermission && <p><i as={Button} onClick={()=> onPlusMinusClick(-1, character.stress.current, "stress" , character.stress.max)} className="me-4 fa-xl fa-solid fa-minus secondary-color attribute-icon"></i><i as={Button} value={1} onClick={()=> onPlusMinusClick(1, character.stress.current, "stress" , character.stress.max)} className="fa-solid fa-xl fa-plus attribute-icon"></i></p>
                                }
                            </Col>
                            <Col className="mx-auto mb-2" lg={3} md={4} xs={6}>
                                <h3 className="primary-color"><p><i className="mt-3 mb-0 fa-solid fa-person-running secondary-color"></i></p>Stamina</h3>
                                <p className="lead card-subtext">{character.stamina.current} of {character.stamina.max}</p>
                                {
                                    editPermission && <p><i as={Button} onClick={()=> onPlusMinusClick(-1, character.stamina.current, "stamina" , character.stamina.max)} className="me-4 fa-xl fa-solid fa-minus secondary-color attribute-icon"></i><i as={Button} value={1} onClick={()=> onPlusMinusClick(1, character.stamina.current, "stamina" , character.stamina.max)} className="fa-solid fa-xl fa-plus attribute-icon"></i></p>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} xs={6}>
                                <Row>
                                    <h3 className="primary-color"><p><i className="mt-3 mb-0 fa-solid fa-wrench secondary-color"></i></p>Mechanics</h3>
                                </Row><Row>
                                <Col md={4} xs={12}>
                                    <h4 className="mb-0">Control</h4>
                                    <p className="lead card-subtext">{character.mechanics.control}</p>
                                </Col>
                                <Col md={4} xs={12}>
                                    <h4 className="mb-0">Create</h4>
                                    <p className="lead card-subtext">{character.mechanics.create}</p>
                                </Col>
                                <Col md={4} xs={12}>
                                    <h4 className="mb-0">Repair</h4>
                                    <p className="lead card-subtext">{character.mechanics.repair}</p>
                                </Col>
                                </Row>
                            </Col>
                            <Col className="border-start border-end" md={4} xs={6}>
                                <Row>
                                    <h3 className="primary-color"><p><i className="mt-3 mb-0 fa-solid fa-brain secondary-color"></i></p>Mind</h3>
                                </Row><Row>
                                <Col md={4} xs={12}>
                                    <h4 className="mb-0">Discuss</h4>
                                    <p className="lead card-subtext">{character.mind.discuss}</p>
                                </Col>
                                <Col md={4} xs={12}>
                                    <h4 className="mb-0">Know</h4>
                                    <p className="lead card-subtext">{character.mind.know}</p>
                                </Col>
                                <Col md={4} xs={12}>
                                    <h4 className="mb-0">Peer</h4>
                                    <p className="lead card-subtext">{character.mind.peer}</p>
                                </Col>
                                </Row>
                            </Col>
                            <Col md={4} xs={12}>
                                <Row>
                                    <h3 className="primary-color"><p><i className="mt-3 mb-0 fa-solid fa-weight-hanging secondary-color"></i></p>Matter</h3>
                                </Row><Row>
                                <Col md={4} xs={6}>
                                    <h4 className="mb-0">Force</h4>
                                    <p className="lead card-subtext">{character.matter.force}</p>
                                </Col>
                                <Col md={4} xs={6}>
                                    <h4 className="mb-0">Resist</h4>
                                    <p className="lead card-subtext">{character.matter.resist}</p>
                                </Col>
                                <Col md={4} xs={12}>
                                    <h4 className="mb-0">Scramble</h4>
                                    <p className="lead card-subtext">{character.matter.scramble}</p>
                                </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mx-auto mb-2" lg={3} md={4} xs={6}>
                                <h4 className="primary-color"><p><i className="mt-3 mb-0 fa-solid secondary-color"></i></p>Talent Points</h4>
                                <p className="lead card-subtext">{character.talentpoints}</p>
                            </Col>
                            <Col className="mx-auto mb-2" lg={3} md={4} xs={6}>
                                <h4 className="primary-color"><p><i className="mt-3 mb-0 fa-solid secondary-color"></i></p>Money (spans)</h4>
                                {editPermission?<p><Form.Control name="money" onChange={handleFieldAutoUpdate} data-bs-theme="light" type="number" value={character.money} /></p>
                                    :<p className="lead card-subtext">{character.money}</p>}                            
                            </Col>
                            <Col className="mx-auto mb-2" lg={3} md={4} xs={6}>
                                <h4 className="primary-color"><p><i className="mt-3 mb-0 fa-solid secondary-color"></i></p>Scars</h4>
                                <p className="lead card-subtext">{character.scars}</p>
                            </Col>
                            <Col className="mx-auto mb-2" lg={3} md={4} xs={6}>
                                <h4 className="primary-color"><p><i className="mt-3 mb-0 fa-solid secondary-color"></i></p>Carry</h4>
                                <p className="lead card-subtext">{character.carry}</p>
                            </Col>
                        </Row>
                        
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        
        <Row className="character-profile-main m-2 mb-5">
                <Tabs defaultActiveKey="traits" id="character-profile" className="pt-0 mb-3" data-bs-theme="dark" fill justify>
                    <Tab eventKey="traits" title={<span><i className="fa-solid fa-person-rays" />  Traits & disadvantages</span>}>
                        <Container fluid className="text-md-start py-3">
                            <Row>
                                <Col className="mx-auto mb-2" lg={3} md={4} xs={6}>
                                    <h4 className="primary-color"><p><i className="mt-3 mb-0 fa-solid secondary-color"></i></p>Background(s)</h4>
                                    <p className="lead card-subtext">{character.background}</p>
                                </Col>
                                <Col className="mx-auto mb-2" lg={3} md={4} xs={6}>
                                    <h4 className="primary-color"><p><i className="mt-3 mb-0 fa-solid secondary-color"></i></p>Profession(s)</h4>
                                    <p className="lead card-subtext">{character.profession}</p>
                                </Col>
                            </Row>
                            <br></br>
                            <h3> Background & Profession Traits</h3>
                            <ListGroup className="mb-5" data-bs-theme="dark" variant="flush">
                                {character.traits && character.traits.map((filterItem, index) => {
                                    return (<Container key={index}>
                                        <ListGroup.Item><div className="ms-2 me-auto">
                                            <div className="fw-bold">{filterItem.name}</div>
                                                {filterItem.description}
                                            </div>
                                        </ListGroup.Item>
                                    </Container>);
                                    }
                                )}
                            </ListGroup>

                            <h3>Disadvantages</h3>
                            <ListGroup data-bs-theme="dark" variant="flush">
                                {character.disadvantages && character.disadvantages.map((filterItem, index) => {
                                    return (<Container key={index}>
                                        <ListGroup.Item><div className="ms-2 me-auto">
                                            <div className="fw-bold">{filterItem.name}</div>
                                                {filterItem.description}
                                            </div>
                                        </ListGroup.Item>
                                    </Container>);
                                    }
                                )}
                            </ListGroup>
                        </Container>
                    </Tab>

                    <Tab eventKey="talents" title={<span><i className="fa-solid fa-lines-leaning" />  Talents</span>}>
                        <Container fluid className="text-md-start py-3">
                            <h3>Talents</h3>
                            <ListGroup data-bs-theme="dark" variant="flush">
                                {character.talents && character.talents.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((filterItem, index) => {
                                    return (<Container key={index}>
                                        <ListGroup.Item><div className="ms-2 me-auto">
                                            <div className="fw-bold">{filterItem.name}</div>
                                                {filterItem.description}
                                            </div>
                                        </ListGroup.Item>
                                    </Container>);
                                    }
                                )}
                            </ListGroup>
                        </Container>
                    </Tab>

                    <Tab eventKey="equipment" title={<span><i className="fa-solid fa-cubes" />  Equipment & gear</span>}>
                        <Container fluid className="text-md-start py-3">
                            <h3>Equipment</h3>
                            <ListGroup data-bs-theme="dark" variant="flush">
                                {character.equipment && character.equipment.map((filterItem, index) => {
                                    return (<Container key={index}>
                                        <ListGroup.Item><div className="ms-2 me-auto align-items-center">
                                            <h4 className="fw-bold">{filterItem.name}</h4>
                                                <p>{filterItem.description}</p>
                                                <Row className="text-md-center">
                                                    {(filterItem.ranged===undefined? armorEquipment(filterItem, index): weaponEquipment(filterItem, index))}
                                                    <Col>
                                                        <Row className="m"><p><strong>Weight</strong></p></Row>
                                                        <Row><p>{filterItem.weight} carry</p></Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </ListGroup.Item>
                                    </Container>);
                                    }
                                )}
                            </ListGroup>

                            <h3 className="mt-5">Gear</h3>
                            <ListGroup data-bs-theme="dark" variant="flush">
                                {character.items && character.items.map((filterItem, index) => {
                                    return (<Container key={index}>
                                        <ListGroup.Item><div className="ms-2 me-auto">
                                            <h4 className="fw-bold">{filterItem.name}</h4>
                                                <p>{filterItem.description}</p>
                                                <Row className="mt-4 text-md-center">
                                                    <Col>
                                                        <Row className="m"><p><strong>Weight</strong></p></Row>
                                                        <Row><p>{filterItem.weight} carry</p></Row>
                                                    </Col>
                                                    <Col>
                                                        <Row><p><strong>Quantity</strong></p></Row>
                                                        <Row><p>{filterItem.quantity}</p></Row>
                                                    </Col>
                                                    <Col>
                                                        <Row><p><strong>Cost</strong></p></Row>
                                                        <Row><p>{filterItem.cost} span</p></Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </ListGroup.Item>
                                    </Container>);
                                    }
                                )}
                            </ListGroup>
                        </Container>
                    </Tab>
                    <Tab eventKey="custom-skills" title={<span><i className="fa-solid fa-user-pen" />  Custom skills</span>}>
                        <Container fluid className="text-md-start py-3">
                            <h3 className="mt-5">Custom Skills</h3>
                            <ListGroup data-bs-theme="dark" variant="flush">
                                {character.skills? character.skills.map((filterItem, index) => {
                                    return (<Container key={index}>
                                        <ListGroup.Item><div className="ms-2 me-auto">
                                            <h4 className="fw-bold">{filterItem.name}</h4>
                                                <p>{filterItem.description}</p>
                                                <Row className="mt-4 text-md-center">
                                                    <Col>
                                                        <Row className="m"><p><strong>Modifier</strong></p></Row>
                                                        <Row><p>{filterItem.modifier}</p></Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </ListGroup.Item>
                                    </Container>);
                                    }
                                ): <p className="lead text-md-center">No custom skills created</p>}
                            </ListGroup>
                        </Container>
                    </Tab>

                    { editPermission &&
                    <Tab eventKey="notes" title={<span><i className="fa-solid fa-book" />  Notes</span>}>
                        <Container fluid className="text-md-start py-3">
                            <h3>Private notes</h3>
                            <p>{character.notes}</p>
                        </Container>
                    </Tab>
                    }
                </Tabs>
            </Row>  
            </Container>)
    }

    const editModeContent = () => {
        return (<Container>{editPermission && <Container fluid>
            <Container>
            <Row>
                <Container className="d-grid mb-5 gap-2">
                        {editPermission && editButtons()}
                </Container>
            </Row>
            <Row>
                <Col md={6} xs={12}>
                    <div className="d-inline-flex align-middle character-avatar-container">
                        <Image data-bs-theme="dark" loading="lazy" alt={character.name} src={characterArt} />
                    </div>
                    <Form.Group data-bs-theme="dark" controlId="formFile" className="mb-3">
                            <Form.Label>Upload character art</Form.Label>
                            <Form.Control name="characterArtUpload" accept="image/*" onChange={handleUpload} type="file" />
                            {uploadState > 0 && uploadState < 101 && <ProgressBar now={uploadState} label={`${uploadState}%`} visuallyHidden></ProgressBar>}
                    </Form.Group>
                    
                </Col>
                <Col md={6} xs={12}>
                    <Form.Control placeholder="Name" name="name" onChange={handleFormChange} data-bs-theme="dark" size="lg" type="text" value={character.name} />
                    <Form.Control placeholder="Nickname" name="nickname" onChange={handleFormChange} data-bs-theme="dark" size="lg" type="text" value={character.nickname} />
                    <p className="subtext mb-5">of skal <Form.Control name="skal" onChange={handleFormChange}  data-bs-theme="dark" type="text" value={character.skal} /></p>
                    <p className="lead">Public notes <br /><Form.Control as="textarea" name="publicnotes" onChange={handleFormChange} rows={7} data-bs-theme="dark" type="text" value={character.publicnotes} /></p>
                </Col>
            </Row>          
        </Container>
        
        <Row className="m-2">
            <Col>
                <Card className="character-profile-card my-5 py-2 shadow-sm">
                    <Card.Title><h2>Trials of the Watcher</h2><p className="lead"><Form.Label>Player display name</Form.Label><Form.Control placeholder="Player display name" name="playername" onChange={handleFormChange}  data-bs-theme="dark" type="text" value={character.playername} /></p></Card.Title>
                    <Card.Body className="pb-2 text-center">
                        <Row>
                            <Col className="mx-auto mb-2" lg={3} md={4} xs={6}>
                                <h3 className="primary-color"><p><i className="mt-3 mb-0 fa-solid fa-shield-halved secondary-color"></i></p>Armor</h3>
                                <p className="lead card-subtext"><Form.Control name="armor" onChange={handleFormChange}  data-bs-theme="dark" type="number" value={character.armor} /></p>
                            </Col>
                            <Col className="mx-auto mb-2" lg={3} md={4} xs={6}>
                                <h3 className="primary-color"><p><i className="mt-3 mb-0 fa-solid fa-heart-crack secondary-color"></i></p>Max Health</h3>
                                <p className="lead card-subtext"><Form.Control name="max" onChange={(event)=> {handleSubAttributeChange("health", character.health, event.target.name, event.target.value)}}  data-bs-theme="dark" type="number" value={(character.health.max)} /></p>
                            </Col>
                            <Col className="mx-auto mb-2" lg={3} md={4} xs={6}>
                                <h3 className="primary-color"><p><i className="mt-3 mb-0 fa-solid fa-heart-crack secondary-color"></i></p>Max Stress</h3>
                                <p className="lead card-subtext"><Form.Control name="max" onChange={(event)=> {handleSubAttributeChange("stress", character.stress, event.target.name, event.target.value)}}  data-bs-theme="dark" type="number" value={character.stress.max} /></p>
                            </Col>
                            <Col className="mx-auto mb-2" lg={3} md={4} xs={6}>
                                <h3 className="primary-color"><p><i className="mt-3 mb-0 fa-solid fa-person-running secondary-color"></i></p>Max Stamina</h3>
                                <p className="lead card-subtext"><Form.Control name="max" onChange={(event)=> {handleSubAttributeChange("stamina", character.stamina, event.target.name, event.target.value)}}  data-bs-theme="dark" type="number" value={character.stamina.max} /></p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} xs={6}>
                                <Row>
                                    <h3 className="primary-color"><p><i className="mt-3 mb-0 fa-solid fa-wrench secondary-color"></i></p>Mechanics</h3>
                                </Row><Row>
                                <Col lg={4} md={6} xs={12}>
                                    <h4 className="mb-0">Control</h4>
                                    <p className="lead card-subtext"><Form.Control name="control" onChange={(event)=> {handleSubAttributeChange("mechanics", character.mechanics, event.target.name, event.target.value)}}  data-bs-theme="dark" type="number" value={character.mechanics.control} /></p>
                                </Col>
                                <Col lg={4} md={6} xs={12}>
                                    <h4 className="mb-0">Create</h4>
                                    <p className="lead card-subtext"><Form.Control name="create" onChange={(event)=> {handleSubAttributeChange("mechanics", character.mechanics, event.target.name, event.target.value)}} data-bs-theme="dark" type="number" value={character.mechanics.create} /></p>
                                </Col>
                                <Col lg={4} md={12} xs={12}>
                                    <h4 className="mb-0">Repair</h4>
                                    <p className="lead card-subtext"><Form.Control name="repair" onChange={(event)=> {handleSubAttributeChange("mechanics", character.mechanics, event.target.name, event.target.value)}} data-bs-theme="dark" type="number" value={character.mechanics.repair} /></p>
                                </Col>
                                </Row>
                            </Col>
                            <Col className="border-start border-end" md={4} xs={6}>
                                <Row>
                                    <h3 className="primary-color"><p><i className="mt-3 mb-0 fa-solid fa-brain secondary-color"></i></p>Mind</h3>
                                </Row><Row>
                                <Col lg={4} md={6} xs={12}>
                                    <h4 className="mb-0">Discuss</h4>
                                    <p className="lead card-subtext"><Form.Control name="discuss" onChange={(event)=> {handleSubAttributeChange("mind", character.mind, event.target.name, event.target.value)}} data-bs-theme="dark" type="number" value={character.mind.discuss} /></p>
                                </Col>
                                <Col lg={4} md={6} xs={12}>
                                    <h4 className="mb-0">Know</h4>
                                    <p className="lead card-subtext"><Form.Control name="know" onChange={(event)=> {handleSubAttributeChange("mind", character.mind, event.target.name, event.target.value)}} data-bs-theme="dark" type="number" value={character.mind.know} /></p>
                                </Col>
                                <Col lg={4} md={12} xs={12}>
                                    <h4 className="mb-0">Peer</h4>
                                    <p className="lead card-subtext"><Form.Control name="peer" onChange={(event)=> {handleSubAttributeChange("mind", character.mind, event.target.name, event.target.value)}} data-bs-theme="dark" type="number" value={character.mind.peer} /></p>
                                </Col>
                                </Row>
                            </Col>
                            <Col md={4} xs={12}>
                                <Row>
                                    <h3 className="primary-color"><p><i className="mt-3 mb-0 fa-solid fa-weight-hanging secondary-color"></i></p>Matter</h3>
                                </Row><Row>
                                <Col lg={4} md={6} xs={6}>
                                    <h4 className="mb-0">Force</h4>
                                    <p className="lead card-subtext"><Form.Control name="force" onChange={(event)=> {handleSubAttributeChange("matter", character.matter, event.target.name, event.target.value)}} data-bs-theme="dark" type="number" value={character.matter.force} /></p>
                                </Col>
                                <Col lg={4} md={6} xs={6}>
                                    <h4 className="mb-0">Resist</h4>
                                    <p className="lead card-subtext"><Form.Control name="resist" onChange={(event)=> {handleSubAttributeChange("matter", character.matter, event.target.name, event.target.value)}} data-bs-theme="dark" type="number" value={character.matter.resist} /></p>
                                </Col>
                                <Col lg={4} md={12} xs={12}>
                                    <h4 className="mb-0">Scramble</h4>
                                    <p className="lead card-subtext"><Form.Control name="scramble" onChange={(event)=> {handleSubAttributeChange("matter", character.matter, event.target.name, event.target.value)}} data-bs-theme="dark" type="number" value={character.matter.scramble} /></p>
                                </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mx-auto mb-2" lg={3} md={4} xs={6}>
                                <h4 className="primary-color"><p><i className="mt-3 mb-0 fa-solid secondary-color"></i></p>Talent Points</h4>
                                <p className="lead card-subtext"><Form.Control name="talentpoints" onChange={handleFormChange} data-bs-theme="dark" type="number" value={character.talentpoints} /></p>
                            </Col>
                            <Col className="mx-auto mb-2" lg={3} md={4} xs={6}>
                                <h4 className="primary-color"><p><i className="mt-3 mb-0 fa-solid secondary-color"></i></p>Money</h4>
                                <p className="lead card-subtext"><Form.Control name="money" onChange={handleFormChange} data-bs-theme="dark" type="number" value={character.money} /></p>
                            </Col>
                            <Col className="mx-auto mb-2" lg={3} md={4} xs={6}>
                                <h4 className="primary-color"><p><i className="mt-3 mb-0 fa-solid secondary-color"></i></p>Scars</h4>
                                <p className="lead card-subtext"><Form.Control name="scars" onChange={handleFormChange} data-bs-theme="dark" type="number" value={character.scars} /></p>
                            </Col>
                            <Col className="mx-auto mb-2" lg={3} md={4} xs={6}>
                                <h4 className="primary-color"><p><i className="mt-3 mb-0 fa-solid secondary-color"></i></p>Carry</h4>
                                <p className="lead card-subtext"><Form.Control name="carry" onChange={handleFormChange} data-bs-theme="dark" type="number" value={character.carry} /></p>
                            </Col>
                        </Row>            
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        
        <Row className="character-profile-main m-2 mb-5">
                <Tabs defaultActiveKey="traits" id="character-profile" className="pt-0 mb-3" data-bs-theme="dark" fill justify>
                    <Tab eventKey="traits" title={<span><i className="fa-solid fa-person-rays" />  Traits & disadvantages</span>}>
                        <Container fluid className="text-md-start py-3">
                            <Row>
                                <Col className="mx-auto mb-2" md={6} xs={12}>
                                    <h4 className="primary-color"><p><i className="mt-3 mb-0 fa-solid secondary-color"></i></p>Background(s)</h4>
                                    <p className="lead card-subtext"><Form.Control name="background" onChange={handleFormChange} data-bs-theme="dark" type="text" value={character.background} /></p>
                                </Col>
                                <Col className="mx-auto mb-2" md={6} xs={12}>
                                    <h4 className="primary-color"><p><i className="mt-3 mb-0 fa-solid secondary-color"></i></p>Profession(s)</h4>
                                    <p className="lead card-subtext"><Form.Control name="profession" onChange={handleFormChange} data-bs-theme="dark" type="text" value={character.profession} /></p>
                                </Col>
                            </Row>
                            <h3> Background & Profession Traits</h3>
                            <Button variant="secondary-outline" onClick={()=>{addItem("traits", character.traits, {name:"", description: ""})}} className="primary-color mb-1"><span className="fa-xl fa-solid fa-plus secondary-color me-1"></span> Add new</Button>
                            <ListGroup className="mb-5" data-bs-theme="dark" variant="flush">
                                {character.traits && character.traits.map((filterItem, index) => {
                                    return (
                                        <ListGroup.Item key={index}><div className="ms-2 mb-4">
                                            <div className="my-2 fw-bold">
                                                <Button variant="secondary-outline" onClick={()=> removeItem("traits", character.traits, index)} className="ms-auto mb-1"><span className="fa-l fa-solid fa-x me-1"></span> Remove</Button>
                                                <Form.Control placeholder="Trait name" name="name" onChange={event => handleListChange(event, index, character.traits, "traits")} data-bs-theme="dark" type="text" value={filterItem.name} /></div>
                                                <Form.Control placeholder="Trait description" name="description" onChange={event => handleListChange(event, index, character.traits, "traits")} data-bs-theme="dark" type="text" value={filterItem.description} />
                                            </div>
                                        </ListGroup.Item>
                                    );
                                    }
                                )}
                            </ListGroup>

                            <h3>Disadvantages</h3>
                            
                            {character.disadvantages.length < 2 && <Button variant="secondary-outline" onClick={()=>{addItem("disadvantages", character.disadvantages, {name:"", description: ""})}} className="primary-color mb-1"><span className="fa-xl fa-solid fa-plus secondary-color me-1"></span> Add new</Button>}
                            <ListGroup data-bs-theme="dark" variant="flush">
                                {character.disadvantages.map((filterItem, index) => {
                                    return (<ListGroup.Item key={index}><div className="ms-2 mb-4">
                                            <div className="my-2 fw-bold">
                                                <Button variant="secondary-outline" onClick={()=> removeItem("disadvantages", character.disadvantages, index)} className="ms-auto mb-1"><span className="fa-l fa-solid fa-x me-1"></span> Remove</Button>
                                                <Form.Control placeholder="Disadvantage name" name="name" onChange={event => handleListChange(event, index, character.disadvantages, "disadvantages")} data-bs-theme="dark" type="text" value={filterItem.name} /></div>
                                                <Form.Control placeholder="Disadvantage description" name="description" onChange={event => handleListChange(event, index, character.disadvantages, "disadvantages")} data-bs-theme="dark" type="text" value={filterItem.description} />
                                            </div>
                                        </ListGroup.Item>);
                                    }
                                )}
                            </ListGroup>
                        </Container>
                    </Tab>

                    <Tab eventKey="talents" title={<span><i className="fa-solid fa-lines-leaning" />  Talents</span>}>
                        <Container fluid className="text-md-start py-3">
                            <h3>Talents</h3>
                            <Button variant="secondary-outline" onClick={()=>{addItem("talents", character.talents, {name:"", description: ""})}} className="primary-color mb-1"><span className="fa-xl fa-solid fa-plus secondary-color me-1"></span> Add new</Button>
                            <ListGroup data-bs-theme="dark" variant="flush">
                                {character.talents && character.talents.map((filterItem, index) => {
                                    return (<ListGroup.Item key={index}><div className="ms-2 mb-4">
                                            <div className="my-2 fw-bold">
                                                <Button variant="secondary-outline" onClick={()=> removeItem("talents", character.talents, index)} className="ms-auto mb-1"><span className="fa-l fa-solid fa-x me-1"></span> Remove</Button>
                                                <Form.Control placeholder="Talent name" name="name" onChange={event => handleListChange(event, index, character.talents, "talents")} data-bs-theme="dark" type="text" value={filterItem.name} /></div>
                                                <Form.Control placeholder="Talent description" name="description" onChange={event => handleListChange(event, index, character.talents, "talents")} data-bs-theme="dark" type="text" value={filterItem.description} />
                                            </div>
                                        </ListGroup.Item>);
                                    }
                                )}
                            </ListGroup>
                        </Container>
                    </Tab>

                    <Tab eventKey="equipment" title={<span><i className="fa-solid fa-cubes" />  Equipment & gear</span>}>
                        <Container fluid className="text-md-start py-3">
                            <h3>Equipment</h3>
                            <Button variant="secondary-outline" onClick={()=>{addItem("equipment", character.equipment, {name:"", description: "", armor: 0, cost: 0})}} className="primary-color mb-1"><span className="fa-xl fa-solid fa-plus secondary-color me-1"></span> Add new armor</Button>
                            <Button variant="secondary-outline" onClick={()=>{addItem("equipment", character.equipment, {name:"", description: "", weight: 0, damage: 0, ranged: false})}} className="primary-color mb-1"><span className="fa-xl fa-solid fa-plus secondary-color me-1"></span> Add new weapon</Button>
                            <ListGroup data-bs-theme="dark" variant="flush">
                                {character.equipment && character.equipment.map((filterItem, index) => {
                                    return (
                                        <ListGroup.Item key={index}>
                                            <div className="ms-2 mb-4">
                                                <Button variant="secondary-outline" onClick={()=> removeItem("equipment", character.equipment, index)} className="ms-auto mb-1"><span className="fa-l fa-solid fa-x me-1"></span> Remove</Button>
                                                <Form.Control placeholder="Equipment name" name="name" onChange={event => handleListChange(event, index, character.equipment, "equipment")} data-bs-theme="dark" type="text" value={filterItem.name} />
                                                <Form.Control placeholder="Equipment description" name="description" onChange={event => handleListChange(event, index, character.equipment, "equipment")} data-bs-theme="dark" type="text" value={filterItem.description} />
                                                <Row className="mt-4 text-md-center">
                                                    {(filterItem.ranged===undefined? armorEquipment(filterItem, index): weaponEquipment(filterItem, index))}
                                                    <Col>
                                                        <Row className="m"><p><strong>Weight</strong></p></Row>
                                                        <Row><Form.Control placeholder="Equipment weight (carry)" name="weight" onChange={event => handleListChange(event, index, character.equipment, "equipment")} data-bs-theme="dark" type="number" value={filterItem.weight} /></Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </ListGroup.Item>);
                                    }
                                )}
                            </ListGroup>

                            <h3 className="mt-5">Gear</h3>
                            <Button variant="secondary-outline" onClick={()=>{addItem("items", character.items, {name:"", description: "", weight: 0, cost: 0, quantity: 1})}} className="primary-color mb-1"><span className="fa-xl fa-solid fa-plus secondary-color me-1"></span> Add new</Button>
                            <ListGroup data-bs-theme="dark" variant="flush">
                                {character.items && character.items.map((filterItem, index) => {
                                    return (<ListGroup.Item key={index}>
                                            <div className="ms-2 mb-4">
                                                <Button variant="secondary-outline" onClick={()=> removeItem("items", character.items, index)} className="ms-auto mb-1"><span className="fa-l fa-solid fa-x me-1"></span> Remove</Button>
                                                <Form.Control placeholder="Item name" name="name" onChange={event => handleListChange(event, index, character.items, "items")} data-bs-theme="dark" type="text" value={filterItem.name} />
                                                <Form.Control placeholder="Item description" name="description" onChange={event => handleListChange(event, index, character.items, "items")} data-bs-theme="dark" type="text" value={filterItem.description} />
                                                <Row className="mt-4 text-md-center">
                                                    <Col>
                                                        <Row><p><strong>Weight</strong></p></Row>
                                                        <Row><Form.Control placeholder="Item weight (carry)" name="weight" onChange={event => handleListChange(event, index, character.items, "items")} data-bs-theme="dark" type="number" value={filterItem.weight} /></Row>
                                                    </Col>
                                                    <Col>
                                                        <Row><p><strong>Quantity</strong></p></Row>
                                                        <Row><Form.Control placeholder="Quantity" name="quantity" onChange={event => handleListChange(event, index, character.items, "items")} data-bs-theme="dark" type="number" value={filterItem.quantity} /></Row>
                                                    </Col>
                                                    <Col>
                                                        <Row><p><strong>Cost</strong></p></Row>
                                                        <Row><Form.Control placeholder="Item cost (spans)" name="cost" onChange={event => handleListChange(event, index, character.items, "items")} data-bs-theme="dark" type="number" value={filterItem.cost} /></Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </ListGroup.Item>
                                    );
                                    }
                                )}
                            </ListGroup>
                        </Container>
                    </Tab>
                    <Tab eventKey="custom-skills" title={<span><i className="fa-solid fa-user-pen" />  Custom skills</span>}>
                        <Container fluid className="text-md-start py-3">
                            <h3 className="mt-5">Custom Skills</h3>
                            <Button variant="secondary-outline" onClick={()=>{addItem("skills", character.skills, {name:"", description: "", modifier: 0})}} className="primary-color mb-1"><span className="fa-xl fa-solid fa-plus secondary-color me-1"></span> Add new</Button>
                            <ListGroup data-bs-theme="dark" variant="flush">
                                {character.skills? character.skills.map((filterItem, index) => {
                                    return (<Container key={index}>
                                        <ListGroup.Item><div className="ms-2 me-auto">
                                            <Button variant="secondary-outline" onClick={()=> removeItem("skills", character.skills, index)} className="ms-auto mb-1"><span className="fa-l fa-solid fa-x me-1"></span> Remove</Button>
                                            <Form.Control placeholder="Custom skill name" name="name" onChange={event => handleListChange(event, index, character.skills, "skills")} type="text" value={filterItem.name} />
                                            <Form.Control placeholder="Custom skill description" name="description" onChange={event => handleListChange(event, index, character.skills, "skills")} type="text" value={filterItem.description} />

                                            <Row className="mt-4 text-md-center">
                                                <Col>
                                                    <Row><p><strong>Modifier</strong></p></Row>
                                                    <Row><Form.Control placeholder="Skill modifier" name="modifier" onChange={event => handleListChange(event, index, character.skills, "skills")} data-bs-theme="dark" type="number" value={filterItem.modifier} /></Row>
                                                </Col>
                                            </Row>
                                            </div>
                                        </ListGroup.Item>
                                    </Container>);
                                    }
                                ): <p className="lead text-md-center">No custom skills created</p>}
                            </ListGroup>
                        </Container>
                    </Tab>
                    {editPermission &&
                    <Tab eventKey="details" title={<span><i className="fa-solid fa-book" />  Notes</span>}>
                        <Container fluid className="text-md-start py-3">
                            <h3>Private notes</h3>
                            <p><Form.Control name="notes" as="textarea" onChange={handleFormChange} rows={10} data-bs-theme="dark" type="text" value={character.notes} /></p>
                        </Container>
                    </Tab>
                    }
                </Tabs>
            </Row>  
            </Container>}
        </Container>)
    }

    return(
   <Container fluid>
    {status === "loading "? 
        <Container><Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
        </Container>: 
        <Container fluid>
            {(!characterItem || !currentUser || !character)? <Container><h1>Character not found</h1></Container>:
            <Container fluid>
                {character.health && (editState === 1? viewModeContent() : 
                    (editState === 2? editModeContent() : 
                    <Container><Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner></Container>))
                }
            </Container>}
        </Container>
    }
    </Container>
    )
}

export default Character;