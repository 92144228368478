// Bootstrap Component
import { Accordion, Button, Col, Container, Form, Row } from "react-bootstrap";

// Libraries
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import parse from 'html-react-parser';

// Styles
import "./game.styles.scss";

// Reducer
import { updateDocument } from "../../utils/firebase/firebase.utils";
import { useFirestore, useFirestoreDocData, useUser } from "reactfire";
import { doc} from "firebase/firestore";

const GameNotes = () => {

    // Find corresponding game
    let {gameUrl} = useParams();

     // Get the current user
     const { status, data: currentUser } = useUser();

    // Setup firestore ref
    const firestore = useFirestore();

    // Set up game item
    const ref = doc(firestore, 'games', gameUrl);
    const { data: gameItem } = useFirestoreDocData(ref);

    // Set up story state
    const [story, setStory] = useState({"sessionnumber": "", "notes": ""});

    /// Create game state item
    const [game, setGame] = useState({});

     // Create navigation reference
     const navigate = useNavigate();

     // After retrieving the game, set the known corresponding states
     useEffect(() => {
         if (currentUser && gameItem) {
            // Set the game and game notes
            setGame(gameItem);            
         } else if (status==="success" && !currentUser) {
              // Redirect to login if not logged in
             navigate("/login");
         }
     }, [currentUser, gameItem, navigate, status]);

     // Handle form changes
    const handleStoryChange = e => {
        const { name, value } = e.target;

        let parsedVal = value;
        // Check if the value is a number, and if so, convert the type
        if (value && !isNaN(value)) {
            parsedVal = parseFloat(value);
        }

        setStory(prevState => ({
            ...prevState,
            [name]: parsedVal
        }));
    };

    
    const addStoryNotes = () => {
        // Push the new item to the list
        const newStoryNotes = game.sessionnotes;
        newStoryNotes.push(story);

        setGame(prevState => ({
            ...prevState,
            "sessionnotes": newStoryNotes
        }));

        setStory({"sessionnumber": "", "notes": ""});

        // Update the character in the backend
        updateDocument("games", gameItem.uid, game);
    }

    const onStorySelect = (e) => {
        const index = game.sessionnotes.findIndex(i => parseFloat(i.sessionnumber) === parseFloat(e.target.value));
        if(index > -1) {
            console.log(game.sessionnotes[index]);
            setStory({"sessionnumber": game.sessionnotes[index].sessionnumber, "notes": game.sessionnotes[index].notes});
        }
    }

    return(<Container fluid className="text-md-start py-3">
    { game && game.sessionnotes &&
        <Container fluid className="mb-5">
            <h1>{gameItem.name}</h1>
            <Row className="my-3">
                {false &&
                <Col className="pe-5 border-end">
                    <p><strong>Select existing session</strong></p>
                    {<Form.Select id="select-session" name="sessionnumber" aria-label="Select session" onChange={onStorySelect}>
                        <option name="default" value={-1}>Select a session</option>
                        {game.sessionnotes.sort((a, b) => a.sessionnumber - b.sessionnumber).map((item, index) => {return <option key={index} value={item.sessionnumber}>{item.sessionnumber}</option>})}
                    </Form.Select>
                    }
                </Col> }
                <Col className="">
                    <p><strong>New session</strong></p>
                    <Form.Control data-bs-theme="dark" name="sessionnumber" onChange={handleStoryChange} value={story.sessionnumber} size="lg" type="number" placeholder="Insert a new session number" />
                </Col>
            </Row>
           
            <ReactQuill theme="snow" name="notes" onChange={(text)=>{handleStoryChange({"target": {"name": "notes", "value": text}})}} value={story.notes}></ReactQuill>
            <Form.Control as={Button} onClick={addStoryNotes}>Submit</Form.Control>

            <h3 className="mt-5">Story so far</h3>
            <Accordion data-bs-theme="dark">
                {game.sessionnotes.sort((a, b) => a.sessionnumber - b.sessionnumber).map((filterItem, index) => {
                    return (<Accordion.Item key={index} eventKey={index}>
                        <Accordion.Header>Session {filterItem.sessionnumber}</Accordion.Header>
                        <Accordion.Body>{parse(filterItem.notes)}</Accordion.Body>
                    </Accordion.Item>);
                    }
                )}
            </Accordion>
        </Container>}
    </Container>);

}
export default GameNotes;