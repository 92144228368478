// Components
import { Button, Col, Container, Row, Spinner} from "react-bootstrap";
import CharacterCard from "../../../components/characters/browse/character-card.component";

// Reducer
import { Link } from "react-router-dom";
import { addNewCharacter } from "../../../utils/firebase/firebase.utils";
import { useFirestore, useFirestoreCollectionData, useUser } from "reactfire";
import { collection, orderBy, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";


const AuthCharacterBrowse = () => {
    // Get the current user
    const { data: currentUser } = useUser();

    // Setup firestore ref
    const firestore = useFirestore();

    // set up query
    const charactersCollection = collection(firestore, 'characters');
    const [charactersQuery, setQuery] = useState(query(charactersCollection, where('users', 'array-contains-any', ["0000"])))

    // After retrieving the character, set the known corresponding states
    useEffect(() => {
        if (currentUser) {
            setQuery(query(charactersCollection, where('users', 'array-contains-any', [currentUser.uid]), orderBy('name', 'asc')));
        }
    }, [currentUser, firestore, charactersCollection]);

    // ReactFire!
    const { status, data: characters } = useFirestoreCollectionData(charactersQuery);

    // Create the start blank character
    let blankCharacter = {"armor": 0, "background": "", "carry": 2, "disadvantages": [], "equipment":[], "game": "aKdYDydNAj1u80Ecao8q", 
        "health": {"current" : 12, "max": 12}, "imagesrc":"gs://trials-of-the-watcher.appspot.com/characters/placeholder.jpeg", "items":[], 
        "link":"", "matter":{"force":0, "resist":0, "scramble":0}, "mechanics":{"control":0,"create":0,"repair":0}, "mind":{"discuss":0, "know":0, "peer":0}, 
        "money":0, "name":"New Character Name", "nickname":"","notes":"",
        "player": "","playername":"","profession":"","publicnotes":"", "scars":0, "skal":"Dactyl", "skills":[], "stamina":{"current" : 3, "max": 3}, 
        "stress":{"current" : 0, "max": 12}, "talentpoints": 5, "talents":[], "skillpoints": 6, "traits": [], "users":[], "uuid":""};   

    const onClickNewCharacter = () => {
        if (currentUser) {
              // Create the start blank character
              blankCharacter.users = ["l9Ql1zH773eQqkGSaIxVOg9LnTq2", currentUser.uid];
              blankCharacter.player = currentUser.uid;
            
              // Add the character in the backend, then nav there with a site reload
              addNewCharacter(blankCharacter).then((link)=>{window.location.href = link;});
        }
    }

   return (
   <Container fluid>
        {status==="success" && currentUser?
            <Row>
                <Col className="d-flex">
                    <Button as={Link} onClick={onClickNewCharacter} className="me-auto" variant="primary"><i className="fa-solid fa-plus"></i>  Create new character</Button>
                </Col>
            </Row>
        :  <Container fluid>{status!== "success"? <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
            </Spinner>:<Container fluid>
                <h2>No characters found</h2>
                <p className="lead">Once logged in, select the "Create new character" button to create a character.</p>
            </Container>}
            </Container>
        }
        <Row className="my-4">
            {currentUser && characters && characters.filter((filterItem)=>(filterItem.users.includes(currentUser.uid))).map((filterItem) => (<CharacterCard key={filterItem.uuid} characterItem={filterItem} />))}
        </Row>
    </Container>  );
}

export default AuthCharacterBrowse;