// Libraries
import {Route, Routes} from 'react-router-dom';
import { AuthProvider, DatabaseProvider, FirestoreProvider, StorageProvider, useFirebaseApp } from 'reactfire';

// Routes
import Base from './routes/base/base.component';
import Home from './routes/home/home.component';
import Login from './routes/login/login.component';
import Register from './routes/register/register.component';
import TermsOfUse from './routes/compliance/terms-of-use.component';
import PrivacyPolicy from './routes/compliance/privacy-policy.component';
import CharacterBrowse from './routes/browse/characters-browse/characters-browse.component';
import Character from './routes/characters/character.component';
import Game from './routes/games/game.component';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import GameBrowse from './routes/browse/games-browse/games-browse.component';
import EditGame from './routes/games/game-edit.component';
import GameNotes from './routes/games/game-notes.component';


/* pages

- home
- login
- signup
- character
- game
- edit character
- create character
- edit game (GM only)
- upload game media


stretch goals
- profile
- create game
- music player
- create setting
- apply setting


*/

const App = () => {
  // Initialize firebase
  const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`

  // initialize Database and Auth with the normal Firebase SDK functions
  const firestore = getFirestore(app);
  const database = getDatabase(app);
  const auth = getAuth(app);
  const storage = getStorage(app);

  return (
    <AuthProvider sdk={auth}>
      <DatabaseProvider sdk={database}>
        <FirestoreProvider sdk={firestore}>
          <StorageProvider sdk={storage}>
            <Routes>
              <Route path='/' element={<Base />} > 
                <Route index element={<Home />} />
                <Route path='/terms-of-use' element={<TermsOfUse />}  />
                <Route path='/privacy-policy' element={<PrivacyPolicy />}  />
                <Route path='/characters' element={<CharacterBrowse />} />
                <Route path='/characters/:characterUrl' element={<Character />} />
                <Route path="/games" element={<GameBrowse />} />
                <Route path="/games/:gameUrl" element={<Game />} />
                <Route path="/games/:gameUrl/edit" element={<EditGame />} />
                <Route path="/games/:gameUrl/notes/edit" element={<GameNotes />} />
              </Route>

              <Route path='/login' element={<Login /> } />
              <Route path='/register' element={<Register /> } />
            </Routes>
          </StorageProvider>
        </FirestoreProvider>
      </DatabaseProvider>
    </AuthProvider>
  );
}

export default App;
