// Styles
import './featured-hero.styles.scss';

// Bootstrap Components
import { Col, Container, Row } from "react-bootstrap";

// Libraries
import { Link } from 'react-router-dom';

const FeaturedHero = () => {

const {link, imageSrc} = {
    link: '/games/aKdYDydNAj1u80Ecao8q',
    imageSrc: './media/main-pages/wash_map.png',
    };

  return (    
    <Container className="container featured-game">
        <Row className="align-items-center rounded-3 mb-0">
            <Col md={12} lg={6} className="mb-3 mt-3 featured-container">
                        <div className='background-image' style={{backgroundImage: `url(${imageSrc})`}} />
                <div className='featured-body-container'>
                    <h1 className="display-5 fw-bold lh-1 mb-3"><strong className="primary-color">Trials</strong> of the <strong className="primary-color">Watcher</strong></h1>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                        <Link to={link} className="btn btn-primary btn-lg" role="button">game</Link>
                        <Link to="/characters" className="btn btn-outline-dark btn-lg" role="button">characters</Link>
                    </div>
                </div>
            </Col>
        </Row>
    </Container>

  );
}

export default FeaturedHero;
