import styled from 'styled-components';

// Bootstrap
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const LoginImage = styled(Col) `
    background-size: cover;
    background-position: center;
    overflow: hidden;
    border-radius: 50px;
  `

export const LoginDivider = styled.div `
  &:after, &:before {
    content: "";
    flex: 1;
    height: 1px;
    background: #eee;
  }
  `

  export const RegisterLink = styled(Link) `
    text-decoration:none;
    color: #FA9500;
  `