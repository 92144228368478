// Libraries
import { Link, useNavigate } from "react-router-dom";
import { useState} from "react";

// Style
import { RegisterLink, LoginDivider} from "./login.styles";

// Components
import FormInput from "../../components/shared/form-input/form-input.component";
import SocialSigninButton from "../../components/shared/social-signin/social-signin-button.component";
import FormSubmitButton from "../../components/shared/form-submit-button/form-submit-button.component";

// Utils
import { signInWithEmail, signInWithGooglePopup} from "../../utils/firebase/firebase.utils";


// Bootstrap Components
import { Card, Col, Container, Row } from "react-bootstrap";
import { useSigninCheck } from "reactfire";

const defaultFormFields = {
    email: '',
    password: ''
}

const Login = ()=> {
    const [formFields, setFormFields] = useState(defaultFormFields);
    const {email, password} = formFields;

    const { status, data: signInCheckResult } = useSigninCheck();

    // Create navigator
    const navigate = useNavigate();

    // If user is already loggged in, reroute to last page
    if (status==="success" && signInCheckResult.signedIn) navigate(-1);

    const resetForm = () => {
        setFormFields(defaultFormFields);
    }

    const logGoogleUser = async () => {
        await signInWithGooglePopup();

        // Redirect the user
        navigate('/');
    }

    const handleChange = (event) => {
        const {name, value} = event.target;
    
        setFormFields({...formFields, [name]: value});
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        // Authenticate user
        try {
          await signInWithEmail(email, password);
    
          // Reset the form
          resetForm();

          // Redirect the user
          navigate('/');
    
        } catch (err) {
            switch(err.code) {
                case "auth/invalid-login-credentials":
                    alert('Email and password do not match a known user.');
                    break;
                case "auth/user-not-found":
                    alert('No user associated with the provided email.');
                    break;
                default:
                    console.log("User creation encountered an error: ", err);
            }
    
        }   
      };
    
    return (
        <div className="wrapper d-flex flex-column">
          <main className="flex-fill">
          <Container fluid>
                <Container className="py-5">
                    <Row className="d-flex justify-content-center align-items-center h-100 my-md-2">
                        <Col xl={10} >
                            <Card>
                                <Row className="g-0">
                                    <h1 className="display-5 fw-bold align-middle lh-1 mb-3"><strong className="primary-color">Trials</strong> of the <strong className="primary-color">Watcher</strong></h1>
                                    <p><Link to="/"><img src="/media/main-pages/tow-logo.png" alt="Trials of the Watcher" className="footer-logo" /></Link></p>

                                    <Col md={6} lg={8} className="d-flex align-items-center align-middle mx-auto">
                                        <Card.Body className="pb-4 p-lg-2 mx-auto">
                                            <form onSubmit={handleSubmit}>
                                                <div className="text-center mb-3">
                                                    <h6>Sign in with:</h6>
                                                    <SocialSigninButton aria="Google Login" icon="fa-google" logUser={logGoogleUser} />
                                                </div>
                                        

                                                <LoginDivider className="d-flex align-items-center my-4">
                                                    <p className="text-center fw-bold mx-3 mb-0">Or</p>
                                                </LoginDivider>
                                                <FormInput 
                                                    label="Email address" 
                                                    id="loginFormEmail"
                                                    type="email" 
                                                    name="email" 
                                                    value={email}
                                                    onChange={handleChange} 
                                                    autoComplete="username"
                                                    required={true}
                                                />
                                                
                                                <FormInput 
                                                    label="Password" 
                                                    id="loginFormPassword" 
                                                    type="password"
                                                    name="password" 
                                                    value={password}
                                                    onChange={handleChange} 
                                                    autoComplete="current-password"
                                                    minLength="8"
                                                    required={true}
                                                    />

                                                <FormSubmitButton text="Login" />

                                                <p className="mb-4 pb-lg-2" style={{color: '#fff'}}>Don't have an account? <RegisterLink to="/register">Register here</RegisterLink></p>
                                                <RegisterLink to="/terms-of-use" className="me-3 small">Terms of use</RegisterLink>
                                                <RegisterLink to="/privacy" className="small">Privacy policy</RegisterLink>
                                            </form>
                                        </Card.Body>
                                    </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Container>
          </main>
        </div>
    );
}

export default Login;