// Components
import { useEffect, useState } from "react";
import { Accordion, Col, Container, Image, Row } from "react-bootstrap";
import { getFirebaseImage } from "../../../utils/firebase/firebase.utils";



const GameLocation = ({index, filterItem}) => {
    // Create game state item
    const [locationArt, setLocationArt] = useState({});
    
    useEffect(() => {
        if (filterItem.imagesrc) {
            // Load the map image from the backend store
            const getLocationArt = async () => {
                // Create a reference from a Google Cloud Storage URI
                await getFirebaseImage(filterItem.imagesrc)
                .then((artUrl) => {setLocationArt(artUrl)});
            }
    
            // Call for the map image
            getLocationArt();
        }
    }, [filterItem.imagesrc]);


    return ( <Container fluid>
    {filterItem.status==="full"? <Accordion.Item eventKey={String(index)}>
        <Accordion.Header>{filterItem.name}</Accordion.Header>
            <Accordion.Body>
                <Row>
                <Col className="mt-2" xs={12} md={6} lg={3}>{filterItem.imagesrc && 
                        <div className="d-inline-flex align-middle">
                            <Image className="img-fluid" loading="lazy" alt={filterItem.name} src={locationArt} />
                        </div>}
                    </Col>
                <Col>
                    <p>{filterItem.description}</p>
                </Col>
            </Row>
        </Accordion.Body>
    </Accordion.Item> : <Accordion.Item eventKey={String(index)}>
        <Accordion.Header>???</Accordion.Header> 
        <Accordion.Body>
            <Row>
            <Col className="mt-2" xs={12} md={6} lg={3}>{filterItem.imagesrc && 
                    <div className="d-inline-flex align-middle">
                        <Image className="img-fluid" loading="lazy" alt={filterItem.name} src={locationArt} />
                    </div>}
                </Col>
                <Col>
                    <p>???</p>
                </Col>
            </Row>
        </Accordion.Body>
    </Accordion.Item>}
    </Container>
    );
}

export default GameLocation;