// Components
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Image, ListGroup, ProgressBar, Row } from "react-bootstrap";
import { getFirebaseImage, uploadImageToStorage } from "../../../utils/firebase/firebase.utils";



const EditGuide = ({game, gameUrl, index, filterItem, removeItem, handleListChange}) => {
    // Create the upload state
    const [uploadState, setUploadState] = useState(-1);

    // Create game state item
    const [guideArt, setGuideArt] = useState({});
    
    useEffect(() => {
        if (filterItem.imagesrc) {
            // Load the map image from the backend store
            const getGuideArt = async () => {
                // Create a reference from a Google Cloud Storage URI
                await getFirebaseImage(filterItem.imagesrc)
                .then((artUrl) => {setGuideArt(artUrl)});
            }
    
            // Call for the map image
            getGuideArt();
        }
    }, [filterItem.imagesrc]);


    const handleListImageUpload = (e, index, parentName) => {
        // Set variables
        const file = e.target.files[0];
        const name = e.target.name;

        if (file) {
            // Set progress to 1
            setUploadState(1);

            // Create file path
            const filePath = "games/"+gameUrl;

            // Upload to storage
            const getGameImageRef = async (fileRef) => {
                
                // Create a reference from a Google Cloud Storage URI
                const uploadTask = await uploadImageToStorage(fileRef, filePath);


                // Register three observers:
                // 1. 'state_changed' observer, called any time the state changes
                // 2. Error observer, called on failure
                // 3. Completion observer, called on successful completion
                uploadTask.task.on('state_changed', 
                (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                // Set upload progress and show progress bar
                setUploadState(progress);
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                    default:
                        console.log("Other upload state detected")
                }
                }, 
                (error) => {
                    // Handle unsuccessful uploads
                }, 
                (ref) => {
                    setUploadState(100);
                    const artUrl = 'gs://trials-of-the-watcher.appspot.com/' + filePath + "/" + file.name;

                    // Handle successful uploads on complete
                    const eObj = {
                        "target": {
                            "name" : [name],
                            "value": artUrl
                        }
                    };

                    console.log(eObj);

                    // Call for the game image from the backend store
                    handleListChange(eObj, index, game[parentName], parentName);
                });
            }
            // Call the upload function
            getGameImageRef(file);
        }
    }

    return (
    <Container data-bs-theme="dark" key={index}>
        <ListGroup.Item><div className="ms-2 py-3">
            <Button variant="secondary-outline" onClick={()=> removeItem("guide", game.guide, index)} className="ms-auto mb-1"><span className="fa-l fa-solid fa-x me-1"></span> Remove</Button>
            <Row>
                {filterItem.imagesrc && <Col className="mt-2" xs={12} md={4} lg={3}>
                    <div className="d-inline-flex align-middle">
                        <Image className="img-fluid" loading="lazy" alt={filterItem.name} src={guideArt} />
                    </div>
                </Col>}
            <Col>
                <Row className="mt-2">
                    <Col lg={8} md={6} xs={12}>
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Upload guide entry art (optional)</Form.Label>
                            <Form.Control name="imagesrc" accept="image/*" onChange={(e)=> {handleListImageUpload(e, index, "guide")}} type="file" />
                            {uploadState > 0 && uploadState < 101 && <ProgressBar now={uploadState} label={`${uploadState}%`} visuallyHidden></ProgressBar>}
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Label>Share with players?</Form.Label>
                        <Form.Select name="status" aria-label="Select share status" value={filterItem.status} onChange={event => handleListChange(event, index, game.guide, "guide")}>
                            <option name="hidden" value="hidden">Hidden</option>
                            <option name="full" value="full">Fully shared</option>
                            <option name="image" value="image">Image only</option>
                        </Form.Select>
                    </Col>
                </Row>
                <Row className="my-2">
                    <Col xs={12} md={6}>
                        <Form.Label>Guide entry name</Form.Label>
                        <Form.Control placeholder="Guide entry name" name="name" onChange={event => handleListChange(event, index, game.guide, "guide")} type="text" value={filterItem.name} />
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Label>Guide entry category</Form.Label>
                        <Form.Select name="category" aria-label="Select guide entry category" value={filterItem.category} onChange={event => handleListChange(event, index, game.guide, "guide")}>
                            <option name="Artifacts" value="Artifacts">Artifact</option>
                            <option name="Fauna" value="Fauna">Fauna</option>
                            <option name="Plants" value="Plants">Plant</option>
                        </Form.Select>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Form.Label>Guide entry description</Form.Label>
                        <Form.Control  as="textarea" placeholder="Guide entry description" name="description" onChange={event => handleListChange(event, index, game.guide, "guide")} type="text" value={filterItem.description} />
                    </Col>
                </Row>
            </Col>
        </Row>
            
            
            </div></ListGroup.Item>
    </Container>
    );
}

export default EditGuide;