const FormInput  = ({label, id, ...inputOptions}) => {
  
  return (
    <div className="form-outline mb-3">
        <input id={id} className="form-control" {...inputOptions} />
        <label className="form-label" htmlFor={id}>{label}</label>
    </div>
  )
};

export default FormInput;