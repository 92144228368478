// Components
import FeaturedHero from '../../components/home/featured-hero/featured-hero.component.jsx';
import CallToAction from '../../components/shared/call-to-action/call-to-action.component';

// Libraries
import { useUser } from 'reactfire';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const Home = () => {
  // Get the current user
  const { status, data: currentUser } = useUser();


  // After retrieving the character, set the known corresponding states
  const navigate = useNavigate();
  useEffect(() => {
      if (status==="success" && !currentUser) {
          navigate('/login');
      }
  }, [status, currentUser, navigate]);

  return (
    <div className="App informational-body">   
      <FeaturedHero />

      <CallToAction  />

    </div>
  );
}

export default Home;
