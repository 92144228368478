// Components
import Footer from "../../components/shared/footer/footer.component";
import FormInput from "../../components/shared/form-input/form-input.component";
import FormSubmitButton from "../../components/shared/form-submit-button/form-submit-button.component";
import SocialSigninButton from "../../components/shared/social-signin/social-signin-button.component";

// Libraries
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Utils
import { createAuthUserWithEmailAndPassword, createUserDocumentFromAuth, signInWithGooglePopup } from "../../utils/firebase/firebase.utils";


// Bootstrap Components
import { Card, Col, Container, Row } from "react-bootstrap";

// Reducer
import { RegisterLink } from "../login/login.styles";
import { useSigninCheck } from "reactfire";

const defaultFormFields = {
  displayName: '',
  email: '',
  password: '',
  confirmPassword: '',
  ingame: "none",
};


const Register  = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const {displayName, email, password, confirmPassword} = formFields;

  // Get current user context
  const { status, data: signInCheckResult } = useSigninCheck();

  // Create navigator
  const navigate = useNavigate();

   // If user logged in, navigate them home
   useEffect(() => {
        if (status==="success" && signInCheckResult.signedIn) {
          navigate("/");
        }
    })


  const resetForm = () => {
    setFormFields(defaultFormFields);
  }

  const logGoogleUser = async () => {
    await signInWithGooglePopup();

    // Redirect the user
    navigate('/');
  }

  const handleChange = (event) => {
    const {name, value} = event.target;

    setFormFields({...formFields, [name]: value});
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Confirm that the password and confirm password match
    if (password !== confirmPassword) {
      alert("Passwords do not match.");
      return ;
    }

    // Authenticate user
    try {
      const {user} = await createAuthUserWithEmailAndPassword(email, password);

      // Set display name
      await createUserDocumentFromAuth(user, {displayName});

      // Reset the form
      resetForm();

      // Redirect the user
      navigate('/');

    } catch (err) {
      if (err.code === "auth/email-already-in-use") {
        alert('User with this email already exists.')
      }

      console.log("User creation encountered an error: ", err);

    }   
  };

  return (
    <div className="wrapper d-flex flex-column">
      <main className="flex-fill">
      <div className=" px-4 py-4 px-md-5 text-center text-lg-start h-100" >
          <Container className="container my-md-3">
            <Row className="row gx-lg-5 align-items-center">
              <Col lg={6} className="mb-5 mb-lg-0">
                <h1 className="my-5 display-3 fw-bold ls-tight">
                  Journey through the <br />
                  <span className="primary-color">Wash</span>
                </h1>
                <p style={{color: "hsl(217, 10%, 50.8%)"}}>
                “To the great Watcher in the sky, maker of day, presenter of trials:
                May I be spared from your burning gaze.
                May our trials be effortless and clear.
                May you be appeased by our journeys.”
                -Old Jauntish Prayer
                </p>
              </Col>
      
              <Col lg={6} className="mb-5 mb-lg-0">
                <Card>
                  <Card.Body className="py-5 px-md-5">
                    <form onSubmit={handleSubmit}>

                      <FormInput 
                        type="text" 
                        id="newUserFormDisplayName" 
                        name="displayName" 
                        value={displayName} 
                        minLength="2" 
                        onChange={handleChange} 
                        label="Name" 
                        autoComplete="name"
                        required={true} />

                      <FormInput 
                        type="email" 
                        id="newUserFormEmail" 
                        name="email" 
                        value={email} 
                        onChange={handleChange} 
                        autoComplete="username" 
                        label="Email address"
                        required={true} />

                      <FormInput 
                        type="password" 
                        id="newUserFormPassword" 
                        name="password" 
                        value={password} 
                        minLength="8" 
                        onChange={handleChange} 
                        autoComplete="new-password" 
                        label="Password"
                        required={true} />

                      <FormInput 
                        type="password" 
                        id="newUserFormConfirmPassword" 
                        name="confirmPassword" 
                        value={confirmPassword} 
                        minLength="8" 
                        onChange={handleChange} 
                        autoComplete="new-password" 
                        label="Confirm password"
                        required={true} />
      
                      <div className="form-check d-flex justify-content-center mb-4">
                        <input className="form-check-input me-2" style={{backgroundColor: "#045184"}} required type="checkbox" value="" id="newUserFormAgree" defaultChecked />
                        <label className="form-check-label" htmlFor="newUserFormAgree">
                          I agree to the <RegisterLink to="/terms-of-use">terms of use</RegisterLink> and <RegisterLink to="/privacy-policy">privacy policy</RegisterLink>.
                        </label>
                      </div>
      
                      <FormSubmitButton text="Sign up" />
      
                      <div className="text-center">
                        <p>or sign up with:</p>
                        <SocialSigninButton icon="fa-google" logUser={logGoogleUser} />
                      </div>
                    </form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </main>
      <Footer />
    </div>
  )
};

export default Register;