// Library imports
import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';
import { FirebaseAppProvider } from 'reactfire';

// Style import
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

// Testing import
import reportWebVitals from './reportWebVitals';

//Component import
import App from './App';


// Setup Firebase (reactfire)
const firebaseConfig = {
  apiKey: "AIzaSyBIsB3eWYNktyV4ixsJlzhn65i29Jq7aK0",
  authDomain: "trials-of-the-watcher.firebaseapp.com",
  projectId: "trials-of-the-watcher",
  storageBucket: "trials-of-the-watcher.appspot.com",
  messagingSenderId: "139180409859",
  appId: "1:139180409859:web:e7c2a47583600183e73a36",
  measurementId: "G-CG60D7G077"
};


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <Router>
          <App />
        </Router>
    </FirebaseAppProvider>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
