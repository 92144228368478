// Styles
import './footer.styles.scss';

// Bootstrap Components
import { Col, Container, Row } from "react-bootstrap";

// Libraries
import { Link } from 'react-router-dom';


const Footer = () => {
  
  // Get current year for copyright
  const currentYear = new Date().getFullYear();
  
  return ( 
    <footer className="pt-4 my-md pt-md-5 mt-auto footer">
      <Container fluid >
          <Row className="d-flex justify-content-center mx-2">
              <Col className="mt-auto mb-auto">
                  <p><Link to="/"><img src="/media/main-pages/tow-logo.png" alt="Trials of the Watcher" className="footer-logo" /></Link></p>
                  <small className="d-block mt-4 mb-1">&copy;{currentYear} Guild of Good Intentions</small>
              </Col>
          </Row>
      </Container>
    </footer>
  );
}

export default Footer;
