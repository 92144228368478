// Style
import {TOWOrangeButton} from './social-signin-button.styles';

const SocialSigninButton = ({icon, logUser, aria})=> {
    
    return (
        <TOWOrangeButton type="button" aria-haspopup={true} title={aria} className="btn btn-link btn-floating mx-1" onClick={logUser}>
            <i className={`fab fa-xl ${icon}`}></i>
        </TOWOrangeButton>

    );
}

export default SocialSigninButton;