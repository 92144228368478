// Libraries
import { Outlet } from 'react-router-dom';

// Components
import Header from '../../components/shared/header/header.component';
import Footer from '../../components/shared/footer/footer.component';

const Base = () => {

  return (
    <div className="wrapper d-flex flex-column">
      <Header />
        <main className="flex-fill">
          <Outlet />
        </main>
      <Footer />
    </div>);
};

export default Base;
