// Styles
import { CallToActionSection } from "./call-to-action.styles";

// Bootstrap Components
import { Button, Card, Col, Container, Row } from "react-bootstrap";

//Libraries
import { Link } from "react-router-dom";

const CallToAction = () => {
  return (
      <CallToActionSection id="cta">
        <Container className="container mt-5">
          <h3>Journey through the <strong className="primary-color">Wash</strong></h3>
          <Row className="mt-3">
              <Col sm={6}>
                  <Card data-bs-theme="dark" className="shadow-sm">
                      <Card.Body>
                          <Card.Title><h5>Games</h5></Card.Title>
                          <Card.Text>Play the game or review what's happened so far</Card.Text>
                          <Container className="d-grid gap-2 d-md-flex justify-content-md-center">
                              <Button as={Link} to="/games/aKdYDydNAj1u80Ecao8q" variant="primary" size="lg" >play</Button>
                          </Container>
                      </Card.Body>
                  </Card>
              </Col>
              <Col sm={6}>
                  <Card className="shadow-sm">
                      <Card.Body>
                          <Card.Title><h5>Characters</h5></Card.Title>
                          <Card.Text>Define your character or view your current sheet</Card.Text>
                          <Container className="d-grid gap-2 d-md-flex justify-content-md-center">
                              <Button as={Link} to="/characters" variant="outline-dark" size="lg" >view my characters</Button>
                          </Container>
                      </Card.Body>
                  </Card>
              </Col>
          </Row>
        </Container>
      </CallToActionSection>
    );
}

export default CallToAction;
