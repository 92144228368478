// Components
import { useEffect, useState } from "react";
import { Col, Container, Image, ListGroup,  Row } from "react-bootstrap";
import { getFirebaseImage } from "../../../utils/firebase/firebase.utils";



const NPC = ({filterItem}) => {

    // Create game state item
    const [NPCArt, setNPCArt] = useState({});
    
    useEffect(() => {
        if (filterItem.imagesrc) {
            // Load the map image from the backend store
            const getNPCArt = async () => {
                // Create a reference from a Google Cloud Storage URI
                await getFirebaseImage(filterItem.imagesrc)
                .then((artUrl) => {setNPCArt(artUrl)});
            }
    
            // Call for the map image
            getNPCArt();
        }
    }, [filterItem.imagesrc]);


    return (
    <Container fluid data-bs-theme="dark">
        {filterItem.status === "full"?
        <ListGroup.Item className="border-0 border-bottom px-3 py-3">
            <Row>
                <h5><strong>{filterItem.name}</strong></h5>
            </Row>
            <Row>
            <Col className="mt-2" xs={12} md={6} lg={3}>{filterItem.imagesrc && 
                    <div className="d-inline-flex align-middle">
                        <Image className="img-fluid" loading="lazy" alt={filterItem.name} src={NPCArt} />
                    </div>}
                </Col>
                <Col>
                <p>{filterItem.description}</p>
                </Col>
            </Row>
        </ListGroup.Item>:<ListGroup.Item className="border-0 border-bottom px-3 py-3">
            <Row>
                <h5><strong>???</strong></h5>
            </Row>
            <Row>
            <Col className="mt-2" xs={12} md={6} lg={3}>{filterItem.imagesrc && 
                    <div className="d-inline-flex align-middle">
                        <Image className="img-fluid" loading="lazy" alt={filterItem.name} src={NPCArt} />
                    </div>}
                </Col>
                <Col>
                    <p>???</p>
                </Col>
            </Row>
        </ListGroup.Item>}
    </Container>
    );
}

export default NPC;