// Components
import { Container, Spinner} from "react-bootstrap";
import AuthCharacterBrowse from "./auth-characters-browse.component";

// Libraries
import { useUser } from "reactfire";
import { useEffect} from "react";
import { useNavigate } from "react-router-dom";


const CharacterBrowse = () => {
    // Get the current user
    const { status, data: currentUser } = useUser();


    // After retrieving the character, set the known corresponding states
    const navigate = useNavigate();
    useEffect(() => {
        if (status==="success" && !currentUser) {
            navigate('/login');
        }
    }, [status, currentUser, navigate]);


   return (
   <Container fluid className="px-5">
        {status==="success" && currentUser?
            <AuthCharacterBrowse />
        :  <Container fluid>{status!== "success"? <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
            </Spinner>:<Container fluid>
                <h2>No characters found</h2>
                <p className="lead">Once logged in, select the "Create new character" button to create a character.</p>
            </Container>}
        </Container>
        }
    </Container>  );
}

export default CharacterBrowse;