// Styles
import { useEffect, useState } from "react";
import "./game-card.styles.scss";

// Bootstrap Components
import { Card, Col} from "react-bootstrap";

// Libraries
import { Link } from "react-router-dom";
import { getFirebaseImage } from "../../../utils/firebase/firebase.utils";


const GameCard = ({gameItem}) => {
    // Create the edit state
    const [gameArt, setGameArt] = useState(undefined);

    useEffect(() => {
        if(gameItem) {
            // Load the game image from the backend store
            const getGameImage = async () => {
                // Create a reference from a Google Cloud Storage URI
                await getFirebaseImage(gameItem.map)
                .then((artUrl) => {setGameArt(artUrl)});
            }
        
            // Call for the game image
            getGameImage();
        } else {
            console.log("Game item not found");
        }
    }, [gameItem]);

    return (<Col className="mb-2" xl={3} lg={4} md={6} xs={12} >
    {gameItem &&
        <Card className="shadow-ml game-browse-card">
            <Link to={gameItem.link}>
                <Card.Img variant="top" className="img-card" src={gameArt} />
            </Link>
            <Card.Body className="mt-0 pt-0">
                <Link className="text-decoration-none stretched-link" to={gameItem.link}>
                {gameItem.shortname.length>0?<Card.Title><strong>{gameItem.name} ({gameItem.shortname})</strong></Card.Title>:<Card.Title><strong>{gameItem.name}</strong></Card.Title>}
                </Link>  
            </Card.Body>
        </Card>
    }
    </Col>
    );
};

export default GameCard;